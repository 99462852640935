@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: 700;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.ttf") format("truetype");
}

@font-face {
  font-family: Open Sans Bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Open Sans ExtraBold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-extrabold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-extrabold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-extrabold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-extrabold-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Open Sans Light;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Open Sans;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Open Sans SemiBold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.ttf") format("truetype");
  font-display: swap;
}

.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
  float: left;
  margin: 0;
  display: block;
}

.container .grid_1 {
  width: 80px;
}

.container .grid_2 {
  width: 160px;
}

.container .grid_3 {
  width: 240px;
}

.container .grid_4 {
  width: 320px;
}

.container .grid_5 {
  width: 400px;
}

.container .grid_6 {
  width: 480px;
}

.container .grid_7 {
  width: 560px;
}

.container .grid_8 {
  width: 640px;
}

.container .grid_9 {
  width: 720px;
}

.container .grid_10 {
  width: 800px;
}

.container .grid_11 {
  width: 880px;
}

.container .grid_12 {
  width: 960px;
}

.inner_1, .inner_2, .inner_3, .inner_4, .inner_5, .inner_6, .inner_7, .inner_8, .inner_9, .inner_10, .inner_11, .inner_12 {
  float: left;
  margin: 0 0 10px;
  padding: 10px 10px 0;
  display: block;
}

.outer_1, .outer_2, .outer_3, .outer_4, .outer_5, .outer_6, .outer_7, .outer_8, .outer_9, .outer_10, .outer_11, .outer_12 {
  float: left;
  margin: 0 10px;
  display: block;
}

.container .outer_1, .container .inner_1 {
  width: 60px;
}

.container .outer_2, .container .inner_2 {
  width: 140px;
}

.container .outer_3, .container .inner_3 {
  width: 220px;
}

.container .outer_4, .container .inner_4 {
  width: 300px;
}

.container .outer_5, .container .inner_5 {
  width: 380px;
}

.container .outer_6, .container .inner_6 {
  width: 460px;
}

.container .outer_7, .container .inner_7 {
  width: 540px;
}

.container .outer_8, .container .inner_8 {
  width: 620px;
}

.container .outer_9, .container .inner_9 {
  width: 700px;
}

.container .outer_10, .container .inner_10 {
  width: 780px;
}

.container .outer_11, .container .inner_11 {
  width: 860px;
}

.container .outer_12, .container .inner_12 {
  width: 940px;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.margin-auto {
  margin: 0 auto;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.p-5 {
  padding: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.p-10 {
  padding: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.p-15 {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.p-25 {
  padding: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.p-30 {
  padding: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.p-90 {
  padding: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.height-10 {
  height: 10px;
}

.width-10 {
  width: 10px;
}

.height-20 {
  height: 20px;
}

.width-20 {
  width: 20px;
}

.height-30 {
  height: 30px;
}

.width-30 {
  width: 30px;
}

.height-40 {
  height: 40px;
}

.width-40 {
  width: 40px;
}

.height-50 {
  height: 50px;
}

.width-50 {
  width: 50px;
}

.height-60 {
  height: 60px;
}

.width-60 {
  width: 60px;
}

.height-70 {
  height: 70px;
}

.width-70 {
  width: 70px;
}

.height-80 {
  height: 80px;
}

.width-80 {
  width: 80px;
}

.height-90 {
  height: 90px;
}

.width-90 {
  width: 90px;
}

.height-100 {
  height: 100px;
}

.width-100 {
  width: 100px;
}

.height-110 {
  height: 110px;
}

.width-110 {
  width: 110px;
}

.height-120 {
  height: 120px;
}

.width-120 {
  width: 120px;
}

.height-130 {
  height: 130px;
}

.width-130 {
  width: 130px;
}

.height-140 {
  height: 140px;
}

.width-140 {
  width: 140px;
}

.height-150 {
  height: 150px;
}

.width-150 {
  width: 150px;
}

.height-160 {
  height: 160px;
}

.width-160 {
  width: 160px;
}

.height-170 {
  height: 170px;
}

.width-170 {
  width: 170px;
}

.height-180 {
  height: 180px;
}

.width-180 {
  width: 180px;
}

.height-190 {
  height: 190px;
}

.width-190 {
  width: 190px;
}

.height-200 {
  height: 200px;
}

.width-200 {
  width: 200px;
}

.text-center {
  text-align: center;
}

#siteversion {
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 34px;
  margin-bottom: 20px;
  padding: 20px 0;
}

#siteversion .siteversion-switch {
  float: left;
  width: 46%;
  max-width: 100%;
  height: 34px;
  max-height: 100%;
  padding: 0 2%;
  display: block;
}

#siteversion .siteversion-switch img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

#siteversion #siteversion-mobile {
  box-sizing: border-box;
  float: right;
  border-left: 1px solid #ddd;
}

.copyrightPop {
  text-align: center;
  background: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  padding: 10px;
}

.copyrightPop .ui-dialog-titlebar {
  padding: 5px 0;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 1.3em;
}

.copyrightPop .ui-dialog-titlebar-close {
  display: none;
}

.copyrightPop .ui-button {
  margin: 10px 0 5px;
  padding: 5px 10px;
}

#copyrightpopup {
  z-index: 1000;
  background: #fff;
  border: 1px solid #000;
  width: 640px;
  height: 380px;
  margin-left: 151px;
  padding: 10px;
  position: fixed;
  top: 50px;
  overflow-x: auto;
  overflow-y: scroll;
}

#copyrightpopup h4 {
  color: #333;
  font-size: 24px;
}

#copyrightpopupclose {
  color: #fff;
  cursor: pointer;
  text-align: center;
  background: #000;
  width: 141px;
  height: 29px;
  margin-left: 672px;
  font-family: Open Sans Bold, arial, sans-serif;
  line-height: 28px;
  position: fixed;
  top: 21px;
}

#copyrightpopup p {
  color: #333;
  margin: 6px 0;
  padding-bottom: 2px;
  font-size: 14px;
  line-height: 18px;
}

#copyrightpopup h1 {
  color: #333;
}

.random-horoscope .sign-up {
  background: #fff;
}

.sign-up {
  float: right;
  background: #7323eb;
  width: 320px;
}

.article {
  position: relative;
}

.article .sign-up {
  margin-bottom: 10px;
}

.sign-up .sign-up-wrapp {
  padding: 0 20px 20px;
  display: none;
}

.article .sign-up .sign-up-wrapp {
  margin-bottom: 0;
}

.article .sign-up .form-right {
  float: left;
  width: 260px;
}

.sign-up h3.horoskopy-title {
  color: #fff;
  opacity: .5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sign-up #js-sign-up-name {
  background-color: #fff;
  border: none;
  width: 240px;
  padding: 10px;
}

.sign-up .birth-date select {
  color: #827d78;
  background: #fff;
  border: none;
  width: 300px;
  margin: 10px 0;
  padding: 7px;
}

.sign-up #js-sign-up-day {
  width: 65px;
  margin-right: 15px;
}

.sign-up #js-sign-up-month {
  width: 82px;
  margin-right: 15px;
}

.sign-up #js-sign-up-year {
  width: 75px;
}

.sign-up .female-male {
  color: #fff;
}

.sign-up .input-select.is-invalid, .sign-up .input-text.is-invalid {
  background-color: #fdcce9;
  border-color: #db0a5b;
}

#js-sign-up-submit {
  text-transform: uppercase;
  color: #fff;
  background: #fa5ab9;
  border: none;
  width: 260px;
  margin-top: 8px;
  padding: 10px;
}

#js-sign-up-submit:hover {
  cursor: pointer;
  background: #db0a5b;
}

.sign-up .outLinks {
  background: #f8f8f8;
}

.sign-up .srdce, .sign-up .karta, .sign-up .snar, .sign-up .kyvadlo {
  float: left;
  width: 90px;
  padding: 10px 5px;
  display: block;
}

.sign-up .kyvadlo {
  display: none;
}

.article .sign-up .kyvadlo {
  display: block;
}

.sign-up a.srdce, .sign-up a.karta, .sign-up a.snar, .sign-up a.kyvadlo {
  text-decoration: none;
}

.sign-up a.srdce:hover, .sign-up a.karta:hover, .sign-up a.snar:hover, .sign-up a.kyvadlo:hover {
  background: #eee;
}

.sign-up .picWrapp {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
}

.sign-up .textLink {
  text-align: center;
  width: 90px;
  margin: 0 auto;
  display: block;
}

.sign-up .srdce .picWrapp {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/horoskopy/srdce.png") no-repeat;
}

.sign-up .karta .picWrapp {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/horoskopy/karta.png") no-repeat;
  width: 30px;
}

.sign-up .snar .picWrapp {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/horoskopy/snar.png") no-repeat;
}

.sign-up .kyvadlo .picWrapp {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/horoskopy/kyvadlo.png") no-repeat;
}

.sign-up .bannerLinkHoroskopy {
  text-align: center;
  background: #7323eb;
  padding: 10px 10px 9px;
  display: none;
}

.sign-up .bannerLinkHoroskopy.active {
  display: block;
}

.sign-up .bannerLinkHoroskopy a {
  color: #fff;
  font-size: 17px;
  text-decoration: none;
}

.sign-up .bannerLinkHoroskopy .whiteArrow {
  float: right;
  margin-top: 3px;
}

.sign-up .whiteArrow {
  vertical-align: middle;
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/horoskopy/white-arrow-right.png") no-repeat;
  width: 10px;
  height: 15px;
  display: inline-block;
}

.sign-up .vstupte {
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/horoskopy/vstupte.png") 0 -5px no-repeat;
  width: 75px;
  height: 30px;
  display: inline-block;
}

.sign-up .horoskopy-supertitle h2 {
  float: left;
  color: #373737;
  margin-left: 10px;
  font-size: 22px;
}

.sign-up .horoskopy-supertitle {
  padding-top: 5px;
}

.sign-up .logedInWrapper {
  border-top: 1px solid #ddd;
  width: 100%;
  padding-bottom: 10px;
  display: none;
}

.sign-up .logedInWrapper.active {
  display: block;
}

.sign-up .welcome {
  color: #7323eb;
  margin: 5px 0 0 10px;
  font-size: 24px;
}

.sign-up .signImg {
  float: left;
  width: 140px;
}

.sign-up .signText {
  float: left;
  color: #373737;
  width: 150px;
  height: 90px;
  margin-top: 25px;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
}

.article .sign-up .signText {
  width: 300px;
}

.sign-up .signLink {
  float: right;
  background: #7323eb;
  width: 20px;
  height: 100px;
  margin-top: 15px;
}

.sign-up .signLink a {
  padding: 40px 7px;
  display: block;
}

.sign-up .horoskopyLink {
  margin-right: 50px;
}

.sign-up .cookie-name {
  display: none;
}

.sign-up .cookie-name.active {
  display: block;
}

#article.notlisted .listingControlButtons {
  display: none;
}

#article.notlisted .artSectionTop button.showStart {
  display: none !important;
}

#article.listed ul.main, #article.listed #article.notlisted ul.main {
  margin: 0;
  padding: 0;
  list-style: none;
}

#article.listed ul.main li {
  font-size: 14px !important;
  line-height: 20px !important;
}

#article.listed ul.main > li {
  display: none;
}

#article.listed ul > li.active {
  display: block;
}

#article.listed button {
  border: 0;
  outline: 0;
  font-family: Open Sans, arial, sans-serif;
}

#article.listed .listingControlButtons {
  display: none;
}

#article.listed .artSectionTop {
  border-top: 1px solid #cad5df;
  padding-top: 14px;
}

#article.listed .artSectionTop .nmbr {
  color: #000;
  border-right: 1px solid #000;
  margin-right: 10px;
  padding-right: 5px;
}

#article.listed .artSectionTop h2 {
  float: left;
  max-width: calc(100% - 70px);
  margin: 5px 0 15px;
  font-size: 20px;
  display: block;
}

#article.listed .listingControlButtons.smallControlButtons.sectionPaging {
  float: right;
  text-align: right;
  width: 61px;
}

#article.listed .numbers.counter {
  float: left;
  color: #000;
  text-align: center;
  width: 55px;
  margin: 4px auto 0 5px;
  font-size: 16px;
  display: none !important;
}

#article.listed .showNext.next, #article.listed .showPrev.prev {
  float: right;
  text-align: center;
  display: none;
}

#article.listed .disabled {
  opacity: .5;
}

#article.listed .artSectionBottom {
  float: left;
  width: 100%;
}

#article.listed .artSectionBottom span {
  display: none;
}

#article.listed .bottom button.startListing, #article.listed .bottom button.showStart {
  margin-bottom: 20px;
}

#article.listed .bottom .numbers.counter {
  display: none !important;
}

#article.listed #control-bottom {
  float: right;
  width: 80px;
  margin-bottom: 20px;
  position: relative;
}

#article.listed #control-bottom .showNext.startListing {
  color: #fff;
  background: #000;
  padding: 10px;
  position: absolute;
  top: -40px;
  left: -300px;
}

.headerGradient {
  display: none;
}

#topcontent {
  float: left;
  width: 940px;
  margin: 10px;
}

.doporucujeme-logo {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/doporucujeme.png") center no-repeat;
  width: 178px;
  height: 199px;
  position: relative;
}

.articles-recommended {
  float: left;
  width: 130px;
  height: 179px;
  margin-left: 0;
  padding: 10px;
  font-family: Open Sans Bold, arial, sans-serif;
  position: relative;
}

.articles-recommended .category-logo {
  max-width: 140px;
  height: 20px;
  margin: 0 0 5px;
  line-height: 20px;
  display: block;
  position: relative;
}

.articles-recommended .category-logo img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.articles-recommended .itemImg {
  float: left;
  width: 130px;
  margin-bottom: 5px;
}

.articles-recommended .itemImg img {
  float: left;
  width: 130px;
  display: block;
}

.articles-recommended h3 {
  float: left;
  width: 130px;
  height: 80px;
  margin-top: 5px;
  line-height: 16px;
  display: block;
  overflow: hidden;
}

.articles-recommended h3 a {
  float: left;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-decoration: none;
  display: block;
}

.html-text {
  font-size: 12px;
  line-height: normal;
}

.html-text a {
  text-decoration: underline;
}

.html-text p {
  font-size: 12px;
  line-height: normal;
}

.margin18 {
  margin: 18px 0;
}

.advert p.adTitle {
  clear: both;
  background: #fff url("https://img2.cncenter.cz/images/newblesk/gray-line.gif") 0 repeat-x;
  margin: 0 !important;
  font-size: 9px !important;
}

.advert p.adTitle span {
  background: #fff;
  padding: 0 5px 0 0;
}

.moveTop {
  position: relative;
  top: -12px;
}

.advertFrame {
  border: 1px dotted #ccc;
  border-top: 0;
}

#leaderboard {
  width: 998px;
  margin: 10px auto;
}

.advert .komercniSdeleni {
  background: url("https://img2.cncenter.cz/images/newblesk/gray-line.gif") 0 100% repeat-x;
  height: auto;
  min-height: 60px;
  margin: 0 0 10px;
  padding: 0 65px 0 0;
  position: relative;
}

.advert .komercniSdeleni .advertImg {
  border: 1px solid #ccc;
  position: absolute;
  top: 0;
  right: 0;
}

.advert .komercniSdeleni .advertText {
  font-size: 12px;
  position: relative;
  top: 5px;
}

#siteobal {
  width: 1258px;
  margin: 0 auto;
  display: block;
  position: relative;
}

#gatemiddle {
  background-color: #d0d0d0;
  width: 998px;
  display: block;
}

#gateleft {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#gateright {
  display: block;
  position: absolute;
  top: 0;
  left: 1128px;
}

#menugate {
  margin: 0 10px 10px;
}

#ao-skyscraper {
  border: 1px dotted #ccc;
  margin: 0 0 30px;
  padding: 5px;
}

#ads-widesquare {
  clear: both;
  margin: 0 0 40px;
}

.ads {
  display: none;
}

.ads-pr-perex {
  float: right;
  width: 148px;
}

.page .ads-pr-perex {
  float: none !important;
  width: 480px !important;
  margin: 0 0 30px !important;
}

.page .ads-pr-perex .itemArticle148 {
  width: 325px !important;
  height: auto !important;
  min-height: 105px !important;
  margin: 0 0 30px !important;
  padding: 0 0 0 155px !important;
  position: relative !important;
}

.page .ads-pr-perex .itemArticle148 h3 {
  font-size: 18px !important;
}

.magazinesBlock.top.sfHover {
  width: 85px !important;
}

.mimibazar-block.customizable {
  border-top: none;
  margin: 0;
}

.mimibazar-block.customizable h2 {
  color: red;
  border-bottom: 1px solid red;
  padding-bottom: 5px;
  font-family: Open Sans Light, sans-serif;
  font-size: 25px;
  line-height: 1.2em;
}

.mimibazar-block.customizable h2 a {
  color: red;
}

.mimibazar-block.customizable .item .image {
  background-color: #e9eff4;
}

.mimibazar-block.customizable .item .title {
  color: #16212d;
}

.mimibazar-block.customizable .item .price {
  color: #707b87;
}

.mimibazar-block.customizable .item .more {
  color: red;
  border-bottom-color: red;
}

.mimibazar-block.customizable .item .more:before, .mimibazar-block.customizable .item .more:after {
  background-color: red;
}

.mimibazar-block.customizable .item .more:hover {
  color: #707b87;
}

.mimibazar-block.customizable .item .more:hover:before, .mimibazar-block.customizable .item .more:hover:after {
  background-color: #707b87;
}

.mimibazar-block.customizable .price {
  color: #707b87;
}

.mimibazar-block.customizable .more {
  color: red;
  border-bottom-color: red;
}

.mimibazar-block.customizable .more:before, .mimibazar-block.customizable .more:after {
  background-color: red;
}

.mimibazar-block.customizable .more:hover {
  color: #707b87;
}

.mimibazar-block.customizable .more:hover:before, .mimibazar-block.customizable .more:hover:after {
  background-color: #707b87;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid rgba(0, 0, 0, 0);
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("../../../../images/blesk-prozeny/redesign-2015/ajax-loader.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-style: normal;
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: rgba(0, 0, 0, 0);
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  color: #fff;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dots {
  text-align: center;
  width: 100%;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -45px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  color: #000;
  opacity: .25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  color: #000;
  opacity: .75;
}

input, label, select, button, textarea {
  vertical-align: middle;
  white-space: normal;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 13px;
  line-height: 1;
  display: inline-block;
}

input:focus {
  outline: 0;
}

input, textarea {
  box-sizing: content-box;
}

button, select {
  box-sizing: border-box;
}

input[type="reset"] {
  box-sizing: border-box;
}

input[type="button"] {
  box-sizing: border-box;
}

input[type="submit"] {
  box-sizing: border-box;
}

input[type="checkbox"] {
  box-sizing: border-box;
}

input[type="radio"] {
  box-sizing: border-box;
}

input[type="checkbox"] {
  width: 13px;
  height: 13px;
}

input[type="radio"] {
  width: 13px;
  height: 13px;
}

input[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  box-sizing: content-box;
}

::-webkit-search-decoration {
  display: none;
}

button {
  width: auto;
  overflow: visible;
}

input[type="reset"] {
  width: auto;
  overflow: visible;
}

input[type="button"] {
  width: auto;
  overflow: visible;
}

input[type="submit"] {
  width: auto;
  overflow: visible;
}

::-webkit-file-upload-button {
  background: none;
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

select[multiple] {
  vertical-align: top;
}

body {
  color: #16212d;
  overflow-anchor: none;
  background: #eee;
  margin: 0;
  font-family: Open Sans, arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

a {
  color: #16212d;
  text-decoration: none;
}

a:hover {
  color: red;
  text-decoration: underline;
  transition: color .15s ease-out;
}

a.background-red:hover {
  color: #fff;
}

#site {
  background: #fff;
  width: 960px;
  margin: 0 auto;
  padding: 0 60px 10px;
}

h1, h2, h3, h4 {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin-top: 1.4em;
  margin-bottom: 1.4em;
}

img {
  display: block;
}

img, input {
  max-width: 100%;
}

.color-black {
  color: #16212d;
}

.color-grey {
  color: #707b87;
}

.color-light-grey {
  color: #cad5df;
}

.color-dark-grey {
  color: #48535f;
}

.color-blue {
  color: #0064ff;
}

.color-red {
  color: red;
}

.color-purple {
  color: #a51e58;
}

.color-bra {
  color: #e6007e;
}

.color-white {
  color: #fff;
}

.color-dark-white {
  color: #e9eff4;
}

.font-light {
  font-family: Open Sans Light, arial, sans-serif;
}

.font-regular {
  font-family: Open Sans, arial, sans-serif;
}

.font-semibold {
  font-family: Open Sans Semibold, arial, sans-serif;
}

.font-bold {
  font-family: Open Sans Bold, arial, sans-serif;
}

.font-extra-large {
  font-size: 30px;
  line-height: 1.2em;
}

.font-large {
  font-size: 25px;
  line-height: 1.2em;
}

.font-medium {
  font-size: 20px;
  line-height: 1.2em;
}

.font-normal {
  font-size: 16px;
  line-height: 1.2em;
}

.font-small {
  font-size: 12px;
  line-height: 1.2em;
}

.font-extra-small {
  font-size: 11px;
  line-height: 1.2em;
}

.font-uppercase {
  text-transform: uppercase;
}

.font-left {
  text-align: left;
}

.font-center {
  text-align: center;
}

.font-right {
  text-align: right;
}

.border-top {
  border-top: 1px solid red;
}

.border-top.border-top-width-1 {
  border-top-width: 1px;
}

.border-top.border-top-width-2 {
  border-top-width: 2px;
}

.border-top.border-top-width-3 {
  border-top-width: 3px;
}

.border-top.border-top-color-red {
  border-top-color: red;
}

.border-top.border-top-color-dark-white {
  border-top-color: #e9eff4;
}

.border-top.border-top-color-light-grey {
  border-top-color: #cad5df;
}

.border-bottom {
  border-bottom: 1px solid red;
}

.border-bottom.border-bottom-width-1 {
  border-bottom-width: 1px;
}

.border-bottom.border-bottom-width-2 {
  border-bottom-width: 2px;
}

.border-bottom.border-bottom-width-3 {
  border-bottom-width: 3px;
}

.border-bottom.border-bottom-color-red {
  border-bottom-color: red;
}

.border-bottom.border-bottom-color-dark-white {
  border-bottom-color: #e9eff4;
}

.border-bottom.border-bottom-color-light-grey {
  border-bottom-color: #cad5df;
}

.border-top-light {
  border-top: 1px solid red;
  padding-top: 7px;
}

.border-top-light.color-red {
  border-top: 1px solid red;
}

.border-top-light.color-dark-white {
  border-top: 1px solid #e9eff4;
}

.border-top-light.color-light-grey {
  border-top: 1px solid #cad5df;
}

.border-bottom-light {
  border-bottom: 1px solid red;
  padding-bottom: 7px;
}

.border-bottom-light.color-red {
  border-bottom: 1px solid red;
}

.border-bottom-light.color-dark-white {
  border-bottom: 1px solid #e9eff4;
}

.border-bottom-light.color-light-grey {
  border-bottom: 1px solid #cad5df;
}

.left-pseudo-border:before {
  content: " ";
  border-left: 1px solid #cad5df;
  width: 1px;
  height: 95%;
  position: absolute;
  top: -2px;
  left: -10px;
}

.left-pseudo-border-tall:before {
  content: " ";
  border-left: 1px solid #cad5df;
  width: 1px;
  height: 95%;
  position: absolute;
  top: 2px;
  left: 0;
}

.right-pseudo-border:after {
  content: " ";
  border-right: 1px solid #cad5df;
  width: 1px;
  height: 95%;
  position: absolute;
  top: -2px;
  right: -10px;
}

.right-pseudo-border-tall:after {
  content: " ";
  border-right: 1px solid #cad5df;
  width: 1px;
  height: 95%;
  position: absolute;
  top: 2px;
  right: 0;
}

.border-round {
  border-radius: 50%;
}

.clear {
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.clearfix:before, .clearfix:after, .cb:before, .cb:after {
  content: " ";
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.clearfix:after, .cb:after {
  clear: both;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-5 {
  top: 5px;
}

.right-5 {
  right: 5px;
}

.bottom-5 {
  bottom: 5px;
}

.left-5 {
  left: 5px;
}

.top-10 {
  top: 10px;
}

.right-10 {
  right: 10px;
}

.bottom-10 {
  bottom: 10px;
}

.left-10 {
  left: 10px;
}

.top-15 {
  top: 15px;
}

.right-15 {
  right: 15px;
}

.bottom-15 {
  bottom: 15px;
}

.left-15 {
  left: 15px;
}

.top-20 {
  top: 20px;
}

.right-20 {
  right: 20px;
}

.bottom-20 {
  bottom: 20px;
}

.left-20 {
  left: 20px;
}

.top-25 {
  top: 25px;
}

.right-25 {
  right: 25px;
}

.bottom-25 {
  bottom: 25px;
}

.left-25 {
  left: 25px;
}

.top-30 {
  top: 30px;
}

.right-30 {
  right: 30px;
}

.bottom-30 {
  bottom: 30px;
}

.left-30 {
  left: 30px;
}

.top-40 {
  top: 40px;
}

.right-40 {
  right: 40px;
}

.bottom-40 {
  bottom: 40px;
}

.left-40 {
  left: 40px;
}

.top-50 {
  top: 50px;
}

.right-50 {
  right: 50px;
}

.bottom-50 {
  bottom: 50px;
}

.left-50 {
  left: 50px;
}

.top-60 {
  top: 60px;
}

.right-60 {
  right: 60px;
}

.bottom-60 {
  bottom: 60px;
}

.left-60 {
  left: 60px;
}

.top-65 {
  top: 65px;
}

.right-65 {
  right: 65px;
}

.bottom-65 {
  bottom: 65px;
}

.left-65 {
  left: 65px;
}

.top-70 {
  top: 70px;
}

.right-70 {
  right: 70px;
}

.bottom-70 {
  bottom: 70px;
}

.left-70 {
  left: 70px;
}

.top-80 {
  top: 80px;
}

.right-80 {
  right: 80px;
}

.bottom-80 {
  bottom: 80px;
}

.left-80 {
  left: 80px;
}

.top-90 {
  top: 90px;
}

.right-90 {
  right: 90px;
}

.bottom-90 {
  bottom: 90px;
}

.left-90 {
  left: 90px;
}

.top-100 {
  top: 100px;
}

.right-100 {
  right: 100px;
}

.bottom-100 {
  bottom: 100px;
}

.left-100 {
  left: 100px;
}

.top-115 {
  top: 115px;
}

.right-115 {
  right: 115px;
}

.bottom-115 {
  bottom: 115px;
}

.left-115 {
  left: 115px;
}

.top-135 {
  top: 135px;
}

.right-135 {
  right: 135px;
}

.bottom-135 {
  bottom: 135px;
}

.left-135 {
  left: 135px;
}

.top-210 {
  top: 210px;
}

.right-210 {
  right: 210px;
}

.bottom-210 {
  bottom: 210px;
}

.left-210 {
  left: 210px;
}

.background-black {
  background-color: #16212d;
}

.background-grey {
  background-color: #707b87;
}

.background-light-grey {
  background-color: #cad5df;
}

.background-dark-grey {
  background-color: #48535f;
}

.background-blue {
  background-color: #0064ff;
}

.background-red {
  background-color: red;
}

.background-white {
  background-color: #fff;
}

.background-dark-white {
  background-color: #e9eff4;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.icon-camera {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/camera.png") no-repeat;
  width: 16px;
  height: 12px;
}

.icon-anonymous-user {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/anonymous-user.png") no-repeat;
  width: 50px;
  height: 50px;
}

.icon-cook-cap {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/cook-cap.png") no-repeat;
  width: 23px;
  height: 27px;
}

.icon-bag {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/bag.png") no-repeat;
  width: 27px;
  height: 27px;
}

.icon-chat-bubbles {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/chat-bubbles.png") no-repeat;
  width: 24px;
  height: 25px;
}

.icon-diet {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/diet.png") no-repeat;
  width: 33px;
  height: 28px;
}

.icon-cup {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/cup.png") no-repeat;
  width: 24px;
  height: 27px;
}

.icon-car {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/car.png") no-repeat;
  width: 40px;
  height: 23px;
}

.icon-enquiry-no {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/enquiry-no.png") no-repeat;
  width: 41px;
  height: 41px;
}

.icon-enquiry-yes {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/enquiry-yes.png") no-repeat;
  width: 41px;
  height: 41px;
}

.icon-figure {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/figure.png") no-repeat;
  width: 18px;
  height: 27px;
}

.icon-glasses {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/glasses.png") no-repeat;
  width: 29px;
  height: 24px;
}

.icon-house {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/house.png") no-repeat;
  width: 16px;
  height: 16px;
}

.icon-horoscope {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/horoscope.png") no-repeat;
  width: 23px;
  height: 23px;
}

.icon-logo-aha-small {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/logo-aha-small.png") no-repeat;
  width: 54px;
  height: 26px;
}

.icon-logo-bpz {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/logo-bpz.png") no-repeat;
  width: 428px;
  height: 92px;
}

.icon-mother {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/mother.png") no-repeat;
  width: 36px;
  height: 36px;
}

.icon-play-small {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/play-small.png") no-repeat;
  width: 20px;
  height: 20px;
}

.icon-play-square {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/play-square.png") no-repeat;
  width: 34px;
  height: 34px;
}

.icon-magnify {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/magnify.png") no-repeat;
  width: 19px;
  height: 19px;
}

.icon-play {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/play.png") no-repeat;
  width: 40px;
  height: 40px;
}

.icon-red-arrow-left {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/red-arrow-left.png") no-repeat;
  width: 15px;
  height: 29px;
}

.icon-red-arrow-right-small {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/red-arrow-right-small.png") no-repeat;
  width: 6px;
  height: 10px;
}

.icon-red-arrow-right {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/red-arrow-right.png") no-repeat;
  width: 15px;
  height: 29px;
}

.icon-round-arrow-left {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/round-arrow-left.png") no-repeat;
  width: 52px;
  height: 52px;
}

.icon-round-arrow-right {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/round-arrow-right.png") no-repeat;
  width: 52px;
  height: 52px;
}

.icon-scissors {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/scissors.png") no-repeat;
  width: 29px;
  height: 29px;
}

.icon-signs {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/signs.png") no-repeat;
  width: 41px;
  height: 27px;
}

.icon-swallow {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/swallow.png") no-repeat;
  width: 33px;
  height: 23px;
}

.icon-white-arrow-left {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/white-arrow-left.png") no-repeat;
  width: 28px;
  height: 33px;
}

.icon-white-arrow-right {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/white-arrow-right.png") no-repeat;
  width: 28px;
  height: 33px;
}

.icon-woman-man {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/icons/woman-man.png") no-repeat;
  width: 24px;
  height: 27px;
}

#header .topLine {
  vertical-align: top;
  margin-top: -10px;
  font-size: 13px;
  line-height: 12px;
}

#header .topLine .magazines {
  line-height: 20px;
  display: none;
}

#header .topLine .magazines a {
  margin: 0 6px;
}

#header .dateTime {
  width: 300px;
}

#header .middleLine {
  border-bottom: 1px dashed #cad5df;
  margin: 0;
  padding: 0 0 4px;
  position: relative;
}

#header .middleLine__btn {
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

#header .middleLine__btn--mm {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/header-btn-mesic-mody.svg");
  width: 150px;
  height: 40px;
}

#header .middleLine__btn--vs {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/header-btn-vanocni-special.png");
  width: 153px;
  height: 57px;
}

#header .fb-like {
  width: 140px;
}

#header .search {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  border-left-color: #cad5df;
  padding-left: 9px;
}

#header .searchBox {
  z-index: 5;
  background: #fff;
  border: 1px solid #cad5df;
  width: 190px;
  height: 20px;
  padding: 2px;
  transition: width 1s;
  position: absolute;
  top: -3px;
  right: -3px;
}

#header .searchBox.inactive {
  display: none;
}

#header #mainsearch .searchField {
  line-height: 20px;
}

#header #mainsearch .icon-magnify {
  text-indent: -9999px;
  cursor: pointer;
  width: 19px;
}

.header-dark {
  z-index: 100;
  background-color: #1e1e1e;
  width: 100%;
  height: 66px;
  position: fixed;
  top: 0;
}

.header-dark:after, .header-dark:before {
  content: "";
  clear: both;
  display: table;
}

.header-dark .header-container {
  width: 1050px;
  margin: auto;
}

.header-dark .header-container .logo {
  float: left;
  width: 100px;
  height: 34px;
  margin: 16px 25px 16px 0;
}

.header-dark .header-container .logo .vip-logo {
  width: 125px;
  margin-top: 5px;
}

.header-dark .header-container .gallery-close {
  color: #d21e1e;
  float: right;
  margin: 20px 0;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 13px;
  display: block;
  position: relative;
}

.header-dark .header-container .gallery-close span {
  text-transform: uppercase;
  position: relative;
  top: 3px;
}

.header-dark .header-container .gallery-close .icon {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 0 0 20px;
}

.header-dark .header-container .titles-container {
  float: left;
  padding: 12px 20px;
  font-family: Open Sans Bold, arial, sans-serif;
}

.header-dark .header-container .titles-container .title {
  color: #aaa;
  float: left;
  text-transform: uppercase;
  font-size: 13px;
}

.header-dark .header-container .titles-container .article-title {
  color: #fff;
  float: left;
  clear: both;
  font-size: 16px;
}

@media screen and (max-width: 760px) {
  body.bg-color-dark {
    padding-top: 0;
  }

  .header-dark {
    padding: 0 10px;
    position: relative;
  }

  .header-dark .header-container {
    width: 100%;
  }

  .header-dark .header-container .titles-container {
    display: none;
  }
}

.menu {
  z-index: 13;
  background: #fff;
  border-bottom: 3px solid red;
  margin-bottom: 20px;
  padding-top: 0;
  position: relative;
}

.menu ul.mainMenu {
  height: 41px;
  margin: 0 0 0 1px;
  padding: 0;
  list-style: none;
}

.menu ul.mainMenu li {
  float: left;
  cursor: pointer;
  height: 21px;
  margin: 0 0 0 -1px;
  padding: 10px 0;
  list-style: none;
}

.menu ul.mainMenu li.active {
  background: red;
}

.menu ul.mainMenu li.active a {
  border-right: none;
}

.menu ul.mainMenu li a {
  border-left: 0 solid;
  border-right: 1px solid #cad5df;
  height: 16px;
  padding: 0 7px;
  line-height: 16px;
}

.menu ul.mainMenu li.active a.parent {
  background: none;
  border-left: 0 solid;
  border-right: 1px solid #f70e5f;
  height: 20px;
  margin-top: 3px;
  line-height: 20px;
}

.menu ul.mainMenu li.active a.parent:hover {
  background: none;
  border-left: 0 solid;
  border-right: 1px solid #d0d0d0;
  text-decoration: none;
}

.menu ul.mainMenu li.colorMenu a {
  color: red;
}

.menu ul.mainMenu li:last-of-type a {
  border-right: none;
}

.menu li.pink {
  color: #fff;
  background-color: #ec008c;
}

.menu li.pink:hover {
  color: #ec008c;
  background-color: #fff;
}

#menu li:hover {
  background: red;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .5);
}

#menu li:hover a {
  color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0);
  text-decoration: none;
}

#menu li.colorMenu a {
  color: red;
}

#menu li.colorMenu.invertColor {
  background: red;
  border-left: 0 solid;
  border-right: 1px solid #d0d0d0;
  text-decoration: none;
}

#menu li.colorMenu.invertColor a {
  color: #fff;
  border-right: 0 solid;
}

#menu li.colorMenu.invertColor:hover {
  box-shadow: none;
  border-left: 0 solid;
  border-right: 0 solid;
  background: none !important;
}

#menu li.colorMenu.invertColor:hover a {
  color: red;
  border-right: 1px solid #cad5df;
}

#menu li.pink {
  background-color: #ec008c;
}

#menu li.pink a {
  color: #fff;
}

#menu li.pink:hover {
  background-color: #fff;
}

#menu li.pink:hover a {
  color: #ec008c;
}

#menu li.no-right-border, #menu li.no-right-border a {
  border-right: none !important;
}

#menu li.active a {
  color: #fff;
}

#menu .secondLevel {
  color: #fff;
  z-index: 11;
  background: red;
  padding: 10px;
  display: none;
  position: absolute;
  top: 41px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .5);
}

#menu .secondLevel li {
  float: none;
  box-shadow: none;
}

#menu .secondLevel li a:hover {
  text-decoration: underline;
}

#menu ul.mainMenu li:hover .secondLevel {
  display: block;
}

.searchForm input.normalInp {
  float: left;
  background: #ebebeb;
  border: none;
  width: 480px;
  height: 28px;
  padding: 8px;
  font-family: Open Sans, sans-serif;
  display: block;
}

.searchForm input.searchButton {
  color: #fff;
  box-sizing: content-box;
  background: red;
  border: none;
  height: 24px;
  margin: 0;
  padding: 10px 8px;
  font-family: Open Sans, sans-serif;
}

.pagination .pages {
  text-align: center;
  float: none;
  width: auto;
}

.pagination .pages a {
  line-height: 29px;
}

.pagination .pages a.aktpage {
  color: #fff;
  background: red;
}

.pagination .pages a.aktpage:hover {
  color: #fff;
}

.pagination a.basehref {
  padding: 0 10px;
  display: block;
}

.pagination a:hover {
  color: red;
  text-decoration: none;
}

.forecastHP {
  visibility: hidden;
  width: 130px;
  height: 92px;
  position: absolute;
  top: 0;
  right: 0;
}

.forecastHP .forecast {
  float: left;
  text-align: center;
  width: 65px;
  height: 80px;
  margin-left: 0;
  padding-top: 10px;
}

.forecastHP .forecast a {
  color: #848f9b;
  text-decoration: none;
}

.forecastHP .forecast.tomorrow span, .forecastHP .forecast.today span {
  float: left;
  margin-right: 5px;
  font-size: 14px;
}

.forecastHP .forecast .ico {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -850px 0 no-repeat;
  width: 45px;
  height: 36px;
  margin: 0 auto;
  display: block;
}

.forecastHP .forecast .ico.jasno {
  background-position: -800px 0;
}

.forecastHP .forecast .ico.polojasno {
  background-position: -860px 0;
}

.forecastHP .forecast .ico.mlha {
  background-position: -920px 0;
}

.forecastHP .forecast .ico.mlha-dest {
  background-position: -980px 0;
}

.forecastHP .forecast .ico.mlha-snih {
  background-position: -1040px 0;
}

.forecastHP .forecast .ico.oblacno {
  background-position: -800px -60px;
}

.forecastHP .forecast .ico.oblacno-bourka, .forecastHP .forecast .ico.polojasno-bourka {
  background-position: -860px -59px;
}

.forecastHP .forecast .ico.oblacno-dest, .forecastHP .forecast .ico.polojasno-dest {
  background-position: -920px -59px;
}

.forecastHP .forecast .ico.oblacno-dest-snih {
  background-position: -980px -59px;
}

.forecastHP .forecast .ico.oblacno-snih, .forecastHP .forecast .ico.polojasno-snih {
  background-position: -1040px -59px;
}

.forecastHP .forecast .ico.zatazeno {
  background-position: -800px -120px;
}

.forecastHP .forecast .ico.zatazeno-bourka {
  background-position: -860px -119px;
}

.forecastHP .forecast .ico.zatazeno-dest {
  background-position: -920px -120px;
}

.forecastHP .forecast .ico.zatazeno-dest-snih {
  background-position: -980px -120px;
}

.forecastHP .forecast .ico.zatazeno-snih {
  background-position: -1040px -120px;
}

.forecastHP .pocasiHP {
  color: #fff;
  float: left;
  text-transform: uppercase;
  background: #23aaff;
  width: 77px;
  height: 38px;
  padding-top: 3px;
  padding-left: 75px;
  font-size: 18px;
  line-height: 18px;
  display: block;
  position: relative;
}

.forecastHP .pocasiHP .frog-ico {
  z-index: 1;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -700px -132px no-repeat;
  width: 71px;
  height: 59px;
  position: absolute;
  top: -10px;
  left: 0;
}

.forecastHP .pocasiHP .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -680px 0 no-repeat;
  width: 7px;
  height: 11px;
  position: absolute;
  top: 14px;
  right: 10px;
}

.promobox {
  position: relative;
}

.promobox .description {
  color: #fff;
  background: rgba(0, 0, 0, .75);
  width: 580px;
  padding: 15px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.topArticles .outer_3.relative h3 {
  overflow: hidden;
}

.border-bottom h1 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2em;
}

.receptySlick {
  margin: 10px 0;
}

.receptySlick .recepiesLeft {
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 0;
}

.receptySlick .recepiesRight {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 0;
}

.online-talk .chatRecords .question .text {
  width: 450px;
}

.online-talk .chatRecords .answer .obrazek img {
  border: 2px solid red;
}

.online-talk .chatRecords .answer .text {
  width: 450px;
}

.online-talk #frmot #otbtnsubmit {
  color: #fff;
  background: red;
  border: none;
  height: 40px;
  padding: 10px 20px;
  font-family: Open Sans, sans-serif, Arial;
}

.online-talk #frmot .rest-chars {
  color: #9ba4ae;
}

.articleText.bbtext .copyThisForm {
  border-bottom: 1px solid #d8d8d8;
  margin: 0;
}

#frmot {
  background-color: #fff;
  border: none;
  margin: 10px 0;
  padding: 0;
}

#frmot.frmDisable, #otmsgssms.disableMsgsSMS, #textsmslimitfull.disableMsgsSMS {
  display: none;
}

#frmot label {
  color: #000;
  float: left;
  margin: 10px 0 0;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 13px;
  display: block;
  width: 84px !important;
}

#frmot #otmyname {
  resize: none;
  border: 1px solid #cad5df;
  width: 178px;
  margin: 10px 0;
  padding: 10px;
  font-family: Open Sans, sans-serif, Arial;
  border-radius: 1px !important;
}

#frmot #dotaz {
  resize: none;
  border: 1px solid #cad5df;
  width: 518px;
  min-height: 90px;
  margin: 10px 0;
  padding: 10px;
  font-family: Open Sans, sans-serif, Arial;
  border-radius: 1px !important;
}

#otshowformr {
  padding: 10px 20px;
  display: none;
}

#otshowupdate {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background: red;
  margin: 10px 0;
  padding: 10px;
  font-family: Open Sans Bold, arial, sans-serif;
}

#otitems .record {
  background: none;
  border-top: 0 solid silver;
  border-bottom: 0;
  border-left: 0 solid silver;
  padding: 20px 0;
  box-shadow: 0 0 silver;
}

.onlineTalkDiv .recordTop .question .topSMS {
  color: #ff0036;
  text-transform: uppercase;
  background: url("https://img2.cncenter.cz/images/newblesk-prozeny/elements-chat-prozeny.png") 0 -184px no-repeat;
  padding: 0 0 0 20px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 13px;
}

.onlineTalkDiv .answer .sipka {
  z-index: 1;
  background: url("https://img2.cncenter.cz/images/newblesk-prozeny/elements-chat-prozeny.png") -169px -211px no-repeat;
  width: 16px;
  height: 9px;
  margin: 0;
  display: block;
  position: absolute;
  top: 2px;
  left: 32px;
}

.onlineTalkDiv .answer .obrazekRam {
  z-index: 2;
  background: url("https://img2.cncenter.cz/images/newblesk-prozeny/elements-chat-prozeny.png") no-repeat;
  width: 70px;
  height: 70px;
  margin: 0;
  display: block;
  position: absolute;
  top: 25px;
  left: 10px;
}

#otmsgssms {
  padding: 0 0 15px;
  display: block;
  position: relative;
}

#otmsgssms .bigBlack {
  color: #000;
  text-align: center;
  margin: 15px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 16px;
  display: block;
}

#otmsgssms .small {
  color: #000;
  text-align: center;
  font-size: 13px;
  display: block;
}

#otmsgssms .bigRed {
  color: #ff0036;
  text-align: center;
  margin: 10px;
  font-size: 13px;
  display: block;
}

#otmsgssms .bigRed .bigBig {
  color: #ff0036;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 17px;
}

#otmsgssms #aswitchform {
  background: url("https://img2.cncenter.cz/images/newblesk-prozeny/elements-chat-prozeny.png") 0 -87px no-repeat;
  width: 185px;
  height: 30px;
  margin: 0 auto;
  display: block;
}

.dalsiDotaz {
  margin-top: .6em;
  margin-bottom: .8em;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.articleText a {
  color: red;
}

.articleText p {
  font-size: 15px;
}

.articleText h2 {
  font-size: 20px;
  line-height: 1.2em;
}

.articleText h2 a {
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 20px;
  line-height: 1.2em;
}

.articleText table {
  background: #e9eff4;
  border: none;
  width: 100%;
}

.articleText table td {
  text-align: center;
  padding: 10px;
  font-weight: 500;
}

.articleText table caption {
  background: #e9eff4;
  border-bottom: 1px solid #cad5df;
  padding: 10px;
  font-family: Open Sans Bold, arial, sans-serif;
}

.articleText .imgInArt .buyWrapp a {
  display: block;
  position: relative;
}

.articleText .imgInArt .buyWrapp .buyButtonPositioner a:hover {
  text-decoration: none;
}

.articleText .imgInArt .buyWrapp .buyButtonPositioner a span.buy-button {
  text-transform: uppercase;
  cursor: pointer;
  color: #e9eff4;
  background: red;
  border: 1px solid #e9eff4;
  border-radius: 3px;
  width: 75px;
  padding: 5px;
  font-size: 16px;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.articleText .imgInArt .buyWrapp .buyButtonPositioner a span.buy-button .buy-arrow {
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid #e9eff4;
  margin-left: 5px;
  font-size: 0;
  display: inline-block;
}

.articleText .imgInArt .buyWrapp .buyButtonPositioner a span.buy-button:active {
  background: #c00;
}

.article-listed a {
  color: red;
}

.article-listed .linkArticle img:not(.lazyload) {
  height: 100%;
}

.linkArticle {
  position: relative;
}

.linkArticle a {
  color: #16212d;
}

.linkArticle a:hover {
  color: red;
}

.linkArticle .title a {
  font-family: Open Sans Bold, arial, sans-serif;
}

.user-review {
  border-bottom: 1px solid #cad5df;
  margin-bottom: 10px;
  padding: 10px 0;
}

.title-divider {
  color: red;
  text-transform: uppercase;
  border-top: 3px solid red;
  padding-top: 5px;
}

.rating-box {
  border-bottom: 1px solid #cad5df;
  margin-bottom: 10px;
  padding: 10px 0;
}

.label-article {
  z-index: 1;
  text-transform: uppercase;
  height: 22px;
  padding: 0 10px;
  font-size: 9px;
  line-height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.label-red {
  color: #fff;
  background: #fe0b0a;
}

.label-orange {
  color: #fff;
  background: #f0a73e;
}

.label-gray {
  color: #fff;
  background: #7f808e;
}

.meta a {
  text-transform: uppercase;
  color: red;
  font-size: 14px;
}

.meta a:hover, .meta a:focus {
  color: #16212d;
}

html #site {
  background: #fff;
}

html #site .wikiBlesk {
  padding-left: 0;
}

.enqMultiWrapper {
  margin: 0;
  overflow: hidden;
}

.enqMultiWrapper .insetHeadSmall {
  color: red;
  text-transform: uppercase;
  border-bottom: 1px solid red;
  padding-bottom: 10px;
  font-size: 30px;
}

.enqMultiChoicesWrapper {
  margin-left: 0;
}

.enqMultiQuestion {
  color: #000;
  margin: 15px 0 20px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 22px;
}

.enqMultiChoices {
  color: #666;
  margin: 10px 0;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 15px;
  line-height: 25px;
  position: relative;
}

.enqMultiWrapper.withImages img {
  float: left;
  width: 40px;
  height: 40px;
}

.enqMultiChoicesImg {
  float: left;
  background-image: url("https://img2.cncenter.cz/images/newblesk/enquiry-multi-radio.png");
  width: 18px;
  margin: 0 10px 0 20px;
}

.enqMultiChoicesImg:hover {
  background-image: url("https://img2.cncenter.cz/images/newblesk/enquiry-multi-radio-over.png");
}

.enqMultiChoicesAnswer {
  color: #16212d;
  padding-left: 40px;
  text-decoration: none;
  display: block;
  position: relative;
}

.enqMultiChoicesAnswer:before, .enqMultiChoicesAnswer:after {
  content: "";
  background-clip: padding-box;
  display: block;
}

.enqMultiChoicesAnswer:before {
  border: 2px solid #d3e6ef;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.enqMultiChoicesAnswer:hover:after {
  background-color: red;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.enqMultiWrapper.withImages .enqMultiChoicesAnswer {
  background: none;
  padding-left: 20px;
  line-height: 40px;
}

.enqMultiChoices a {
  text-decoration: none;
}

.enqMultiVotes {
  color: #666;
  margin: 10px 0 0;
  font-family: Open Sans Bold, arial, sans-serif;
}

.enqMultiThanks {
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 10px;
  font-family: Open Sans Bold, arial, sans-serif;
}

.enqMultiThanks span {
  color: red;
  text-transform: none;
  font-size: 12px;
  display: block;
}

.enqMultiChoicesResultWrapper {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  display: none;
}

.enqMultiWrapper.withImages .enqMultiChoicesResultWrapper {
  padding: 0 0 5px;
}

.enqMultiChoicesResult {
  text-align: left;
  padding: 3px 0 0;
  font-family: Open Sans Bold, arial, sans-serif;
}

.enqMultiWrapper.withImages .enqMultiResultAnswer {
  float: left;
  height: 24px;
  padding-left: 10px;
  line-height: 24px;
  display: block;
}

.enqMultiChoicesBar {
  float: left;
  width: 100%;
  height: 15px;
  font-size: 11px;
  overflow: hidden;
}

.enqMultiChoicesVotes {
  float: left;
  background-color: red;
  max-width: 83%;
  height: 15px;
}

.percMultiChoices {
  float: left;
  text-align: right;
  height: 20px;
  padding: 1px 0 0 5px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 16px;
  line-height: 15px !important;
}

.revoluce-podprd .fl {
  width: 100%;
  position: relative;
}

.revoluce-podprd .fl:after {
  content: " ";
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/sprite.png");
  width: 16px;
  height: 29px;
  display: block;
  position: absolute;
  top: -4px;
  right: 0;
}

.revoluce-podprd .fl a:hover {
  color: #a51e58;
  text-decoration: none;
}

.revoluce-podprd .gmaps-wrapper {
  height: 200px;
  margin: 0 0 15px;
  position: relative;
  overflow: hidden;
}

.revoluce-podprd .gmaps-wrapper iframe {
  margin: 0;
  position: relative;
  top: -46px;
}

.revoluce-podprd .gmaps-link {
  z-index: 1;
  color: #3a84df;
  background: #fff;
  border-radius: 2px;
  padding: 5px 25px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: 1px 1px 8px #444;
}

.revoluce-podprd#podprdy-intro p:first-child {
  margin-bottom: 35px;
}

.revoluce-podprd#podprdy-intro p:last-child {
  position: relative;
  top: -10px;
}

.revoluce-podprd#podprdy-intro .intro-wrapper {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/podprsenkova-revoluce/reklama.jpg") 100% 100% no-repeat;
  padding: 10px 75px 0 0;
}

.revoluce-podprd#podprdy-intro .no-link {
  color: #000;
}

.revoluce-podprd#podprdy-intro .no-link:hover {
  text-decoration: none;
}

.dermacol-promo {
  background: #e9eff4;
  margin: 0 0 15px;
  padding: 25px 20px 100px;
  position: relative;
}

.dermacol-promo .promo-photo {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/podprsenkova-revoluce/taska.png") no-repeat;
  width: 173px;
  height: 167px;
  position: absolute;
  top: 120px;
  right: -10px;
}

.dermacol-promo .promo-text {
  width: 100%;
  line-height: 18px;
}

.dermacol-promo .promo-text--bold {
  font-family: Open Sans Bold, arial, sans-serif;
}

.discount-voucher {
  color: #fff;
  text-transform: uppercase;
  background: #e6007e;
  margin: 0 0 15px;
  padding: 10px 20px;
  font-family: Open Sans Bold, arial, sans-serif;
}

@keyframes bobble {
  0% {
    font-size: 16px;
  }

  50% {
    font-size: 28px;
  }

  75% {
    font-size: 18px;
  }

  100% {
    font-size: 21px;
  }
}

.bobble {
  transform-origin: 50%;
  animation: .3s linear bobble;
}

#menu {
  position: relative;
}

#link-revoluce {
  z-index: 1001;
  position: absolute;
  bottom: 31px;
  right: 0;
}

#revoluce-sizes {
  text-align: center;
  background: #e9eff4;
  margin: 0 0 15px;
  padding: 20px 10px;
  overflow: hidden;
}

#revoluce-sizes input {
  background: #fff;
  border: 1px solid #cad5df;
  width: 80%;
  height: 38px;
  margin: 5px 0 0;
  padding: 0 10px;
}

#revoluce-sizes button:hover {
  cursor: pointer;
}

#revoluce-sizes button:active, #revoluce-sizes button:focus {
  outline: none;
}

#revoluce-sizes label {
  text-align: left;
  width: 46%;
  margin: 0 5px;
  display: inline-block;
  position: relative;
}

#revoluce-sizes label button {
  color: #8293a3;
  background: #eef4f8;
  width: 19px;
  height: 19px;
  position: absolute;
  bottom: 1px;
  right: 5px;
}

#revoluce-sizes label button:active {
  background: #fafcfd;
}

#revoluce-sizes label button + button {
  border-bottom: 1px solid #dae3eb;
  bottom: 20px;
}

#revoluce-sizes .fl {
  margin-bottom: 20px;
}

#revoluce-sizes .fl:after {
  display: none;
}

#revoluce-sizes #submit {
  color: #fff;
  clear: both;
  text-transform: uppercase;
  background: #e6007e;
  width: 160px;
  height: 40px;
  position: relative;
}

#revoluce-sizes #submit:active {
  background: #cd4881;
  top: 1px;
  left: 1px;
}

#revoluce-sizes #result-area {
  color: #e6007e;
  height: 0;
  margin: 20px 0;
  font-size: 0;
  transition: height .1s;
}

#revoluce-sizes #result-area.visible {
  height: 20px;
  font-size: 21px;
}

.error404 {
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
}

.error404 .status {
  color: red;
  background: url("/images/blesk-prozeny/404-bpz.png") 0% no-repeat;
  height: 302px;
  margin: 0 30px 20px;
  padding-left: 240px;
  font-family: Open Sans Light, sans-serif;
  font-size: 120px;
  line-height: 302px;
}

.error404 .apologise .centering {
  margin-bottom: 15px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 28px;
  display: inline-block;
}

.error404 .apologise .centering.smaller {
  margin-bottom: 25px;
  font-size: 16px;
}

.gallery-strip {
  width: 100%;
  margin-top: 10px;
}

.gallery-strip:after, .gallery-strip:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-strip .gallery-margin {
  margin-top: 35px;
}

.gallery-strip a.image {
  float: left;
  width: 110px;
  margin: 0 18px 0 0;
  display: inline-block;
}

.gallery-strip a.image img {
  width: 100%;
  height: auto;
}

.gallery-strip a.text-container {
  margin-top: 7px;
}

.gallery-strip a.additional-value-container .additional-value {
  float: left;
  text-align: left;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 40px;
  line-height: 30px;
  display: block;
}

.gallery-strip a.additional-value-container .additional-value .gallery {
  text-transform: uppercase;
  font-size: 14px;
}

.gallery-items {
  background: #fff;
  height: 62px;
  margin: 10px 0 0;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

.gallery-items ul {
  height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 1px;
  list-style-type: none;
  transition: all .3s;
  position: relative;
}

.gallery-items ul li {
  display: inline-block;
}

.gallery-items ul li a {
  color: #000;
  text-align: center;
  height: 61px;
  display: inline-block;
}

.gallery-items ul li a.hover {
  background-color: rgba(255, 255, 255, .8);
}

.gallery-items ul li:last-child {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 4em);
  height: 61px;
  padding-left: 7em;
  position: absolute;
  bottom: 0;
  right: 5px;
}

.gallery-items ul li:last-child a {
  color: red;
  margin-right: -5px;
  padding: 0;
}

.gallery-items ul li:last-child a:hover {
  text-decoration: none;
}

.gallery-items ul li:last-child a .additional-description {
  margin-left: 2px;
}

.bg-color-dark {
  background-color: #1e1e1e;
}

.gallery-container {
  background-color: #1e1e1e;
  height: 100%;
  margin: auto;
}

.gallery-container .gallery-container {
  width: 1050px;
  height: 620px;
  margin: 35px auto 60px;
}

.gallery-description-container .description {
  margin: 10px 5px 0 0;
  font-family: Open Sans Bold, sans-serif;
  display: inline-block;
}

.gallery-description-container .author {
  color: #707b87;
  margin: 10px 0 0;
}

@media screen and (max-width: 760px) {
  .gallery-container .gallery-container {
    width: 100%;
    height: 350px;
    margin-bottom: 60px;
  }

  .gallery-container .image-description {
    padding: 15px;
  }

  .gallery-main-container .gallery .btn {
    background: rgba(30, 30, 30, .6);
    width: 50px;
  }

  .gallery-main-container .gallery .next .image-count {
    font-size: .8em;
  }
}

.gallery-main-container .owl-carousel .owl-item.active a {
  margin: 0 auto;
}

.header-page .unimenu-h1-h2 {
  border-bottom: 1px solid #cad5df;
  margin: 0;
  padding-bottom: 5px;
}

.unimenu-h1-h2 {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 0;
  display: block;
}

.unimenu-h1-h2 .title {
  float: left;
  color: red;
  margin: 10px 20px 0 0;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 25px;
  line-height: 1.2em;
}

.unimenu-h1-h2 .title > a {
  color: red;
  text-decoration: none;
  transition: all .3s;
}

.unimenu-h1-h2 .title > a:hover {
  color: red;
  text-decoration: underline;
  transition: all .3s;
}

.unimenu-h1-h2 .submenu {
  float: left;
  display: inline-block;
  position: relative;
}

.unimenu-h1-h2 .submenu ul.submenu-items {
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.unimenu-h1-h2 .submenu ul.submenu-items > li {
  color: #16212d;
  background: #ccc;
  padding: 10px 17px;
  font-size: 11px;
  line-height: 25px;
  display: inline-block;
  position: relative;
}

.unimenu-h1-h2 .submenu ul.submenu-items > li a {
  color: #16212d;
  text-decoration: none;
}

.unimenu-h1-h2 .submenu ul.submenu-items > li a:hover {
  color: #16212d;
  text-decoration: underline;
}

.unimenu-h1-h2 .submenu ul.submenu-items > li a > .dataimg-arrow-down {
  width: 9px;
  height: 5px;
  margin: 0 0 3px 5px;
  display: inline-block;
}

.unimenu-h1-h2 .submenu:hover ul.submenu-items {
  display: block;
}

.unimenu-h1-h2 .title-right {
  float: left;
  margin: 10px 0 0;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 11px;
}

.unimenu-h1-h2 .title-right > a {
  color: #16212d;
}

.unimenu-h1-h2 .title-right > a > .dataimg-arrow-right, .unimenu-h1-h2 .title-right > a .dataimg-arrow-right-red {
  width: 5px;
  height: 9px;
  margin-left: 5px;
  display: inline-block;
}

.unimenu-h1-h2 .title-nav h2 {
  float: left;
  color: #16212d;
  border-right: 1px solid #cad5df;
  height: 16px;
  margin: 18px 0 0;
  padding: 0 10px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 11px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.unimenu-h1-h2 .title-nav h2:last-of-type {
  border-right: 0;
}

.unimenu-h1-h2 .title-nav h2 a {
  color: #16212d;
  text-decoration: none;
}

.unimenu-h1-h2 .title-nav h2 a:hover {
  color: #16212d;
  text-decoration: underline;
}

.btn {
  text-align: center;
  text-transform: uppercase;
  min-width: 100px;
  margin: 0 auto;
  padding: 10px 30px;
  font-family: Open Sans Bold, arial, sans-serif;
  display: inline-block;
}

.btn.btn-red {
  color: #fff;
  background-color: red;
}

.main-button {
  text-align: center;
  background-color: red;
  border: 0;
  border-radius: 2px;
  width: 240px;
  padding: 10px 0;
  font-weight: bolder;
  line-height: 20px;
  transition: all .5s;
  display: block;
  position: relative;
  color: #fff !important;
}

.main-button:hover {
  background: #8f1414;
}

.box-admin {
  border-top: 1px solid red;
}

.box-admin .box-admin__header {
  cursor: pointer;
  margin-bottom: 15px;
}

.box-admin article {
  float: left;
  width: 212.5px;
  padding: 0 15px;
  position: relative;
  overflow: hidden;
}

.box-admin article .title {
  height: 72px;
  display: block;
}

.box-admin article:first-child, .box-admin article:nth-child(5) {
  padding-left: 0;
}

.box-admin article:nth-child(4), .box-admin article:nth-child(8) {
  padding-right: 0;
}

.horoscopes--medium .random-horoscope {
  border-bottom: none;
  width: 460px;
}

.horoscopes--medium .random-horoscope a:hover .text, .horoscopes--medium .random-horoscope a:focus .text {
  color: red;
}

.horoscopes--medium .random-horoscope .horoscope-supertitle a > span {
  font-family: Open Sans Light, Open Sans, sans-serif;
  font-size: 25px;
}

.articles-recommended--boxadmin {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;
  display: flex;
}

.articles-recommended__article {
  width: 130px;
  height: 179px;
  margin-left: 0;
  padding: 10px;
  position: relative;
}

.articles-recommended__logo {
  background: url("https://img2.cncenter.cz/images/blesk-prozeny/doporucujeme.png") center no-repeat;
  width: 178px;
  height: 199px;
}

.articles-recommended__link {
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 14px;
}

.articles-recommended__link:hover {
  text-decoration: none;
}

.articles-recommended__img {
  margin-bottom: 5px;
  display: block;
}

.articles-recommended__title {
  height: 80px;
  margin-top: 5px;
  overflow: hidden;
}

.articles-recommended__magazine {
  color: red;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: Open Sans Semibold, arial, sans-serif;
  font-size: 12px;
  display: block;
}

.user-bonuses {
  line-height: 1.2;
}

.user-bonuses *, .user-bonuses :before, .user-bonuses :after {
  box-sizing: border-box;
}

.user-bonuses__group {
  margin-bottom: 30px;
}

.user-bonuses__group:last-child {
  margin-bottom: 0;
}

.user-bonuses__main-title {
  margin: 0 0 15px;
  font-weight: 700;
}

.user-bonuses__type-title {
  margin-bottom: 5px !important;
}

.user-bonuses__list {
  border: 1px solid #dcdcdc;
  margin: 0;
  list-style: none;
  padding: 15px 30px 15px 15px !important;
}

.user-bonuses__item {
  margin-bottom: 35px;
}

.user-bonuses__item:last-child {
  margin-bottom: 0;
}

.user-bonus {
  align-items: flex-start;
  display: flex;
}

.user-bonus__image-wrapper {
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 65px;
  display: flex;
  overflow: hidden;
}

.user-bonus__image {
  object-fit: contain;
  flex-basis: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

.user-bonus__body {
  flex-basis: calc(100% - 75px);
  align-items: flex-start;
  padding-left: 20px;
  display: flex;
}

.user-bonus__info {
  width: 100%;
  padding: 0 30px 0 0;
  line-height: 1.3;
}

.user-bonus__name {
  font-weight: 700;
}

.user-bonus__link {
  color: red;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  display: inline;
}

.user-bonus__link:hover {
  text-decoration: none;
}

.user-bonus__code-wrapper {
  border: 1px solid red;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px 35px;
  position: relative;
}

.user-bonus__code-wrapper:hover, .user-bonus__code-wrapper:focus {
  border-color: #e60000;
}

.user-bonus__code-wrapper--has-code:before {
  content: "Kód byl zkopírován";
  z-index: 1;
  color: #fff;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
  background: #65c61e;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .5s ease-in-out;
  display: flex;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.user-bonus__code-wrapper--copied {
  border-color: #65c61e;
  transition: border-color .5s ease-in-out;
}

.user-bonus__code-wrapper--copied:before {
  opacity: 1;
}

.user-bonus__codeurl-wrapper {
  min-width: 150px;
  max-width: 150px;
  height: 30px;
  margin: auto 0 auto auto;
  position: relative;
}

.user-bonus__codeurl-wrapper .user-bonus__codeurl {
  text-align: center;
  border: 1px solid red;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px;
  display: block;
  overflow: hidden;
}

.user-bonus__get-code-button {
  z-index: 1;
  text-align: left;
  color: #fff;
  border-radius: inherit;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .8);
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.user-bonus__get-code-button:hover:before, .user-bonus__get-code-button:focus:before {
  background-color: #e60000;
  width: 80px;
}

.user-bonus__get-code-button:hover:after, .user-bonus__get-code-button:focus:after {
  border-bottom-color: #e60000;
  border-right-width: 35px;
  left: 80px;
}

.user-bonus__get-code-button:before, .user-bonus__get-code-button:after {
  content: "";
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
}

.user-bonus__get-code-button:before {
  background-color: red;
  width: 86px;
  height: 100%;
  left: 0;
}

.user-bonus__get-code-button:after {
  border-top: none;
  border-bottom: 28px solid red;
  border-left: none;
  border-right: 30px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  left: 86px;
}

.user-bonus__code-wrapper--has-code .user-bonus__get-code-button {
  display: none;
}

.user-bonus__get-code-button-text {
  z-index: 2;
  position: relative;
}

.user-bonus__cut-code-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.user-bonus__code {
  text-align: center;
  pointer-events: none;
  border: none;
  max-width: 100%;
  padding: 0;
}

.user-bonus__code-wrapper--has-code .user-bonus__code {
  pointer-events: auto;
}

.user-bonus__address {
  text-align: right;
  margin-left: auto;
  line-height: 20px;
}

.user-bonus__address-title {
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 3px !important;
}

.user-bonus__code-valid-to-wrapper {
  z-index: 1;
  text-align: center;
  color: #b0b0b0;
  width: 100%;
  padding: 4px 0 0;
  font-size: 10px;
  line-height: 1.1;
  position: absolute;
  top: 100%;
  left: 0;
}

.user-bonus__no-code-error {
  text-align: center;
  color: red;
  align-self: center;
  font-weight: 700;
}

.user-bonus__no-code-error--hidden {
  display: none;
}

.user-bonus-popup-overlay {
  z-index: 1252;
  background-color: rgba(0, 0, 0, .2);
  align-items: flex-start;
  max-height: 100vh;
  padding: 50px 0;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.user-bonus-popup-overlay--hidden {
  display: none;
}

.user-bonus-popup {
  z-index: 1253;
  background-color: #fff;
  border-radius: 3px;
  width: 470px;
  margin: auto;
  padding: 30px 30px 30px 40px;
  font-size: 13px;
  line-height: 1.3;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.user-bonus-popup__close-button {
  cursor: pointer;
  background: #fff url("https://img2.cncenter.cz/images/default/user-bonus/close-btn.svg") center / 16px no-repeat;
  border: none;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  padding: 0;
  display: block;
  position: absolute;
  top: -7px;
  right: -8px;
}

.user-bonus-popup__header {
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.user-bonus-popup__image-wrapper {
  width: 160px;
  height: 80px;
}

.user-bonus-popup__info {
  flex-basis: calc(100% - 160px);
  padding-left: 30px;
}

.user-bonus-popup__code-section {
  text-align: center;
  margin-bottom: 30px;
}

.user-bonus-popup__code-section--hidden {
  display: none;
}

.user-bonus-popup__code-wrapper {
  width: 220px;
  max-width: none;
  margin: 4px auto;
}

.user-bonus-popup__cut-code-button {
  background-size: contain;
  left: 13px;
}

.user-bonus-popup__code {
  text-align: center;
  max-width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.user-bonus-popup__code-valid-to-wrapper {
  text-align: center;
  display: block;
  position: static;
}

.user-bonus-popup__no-code-error {
  margin-bottom: 30px !important;
}

.user-bonus-popup__requirements {
  overflow: auto;
}

.user-bonus-popup__requirements p {
  margin-bottom: 1em !important;
}

.user-bonus__name {
  font-family: Open Sans Bold, arial, sans-serif;
}

.user-bonus__info p {
  margin: 0;
}

.user-bonus__link {
  line-height: 17px;
}

.user-bonus__code {
  font-size: 13px;
  line-height: 15px;
}

.user-bonus__code-valid-to-wrapper {
  white-space: nowrap;
  margin: 0;
}

.user-bonus__no-code-error {
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
}

.user-bonus-popup b {
  font-family: Open Sans Bold, arial, sans-serif;
}

.user-bonus-popup__code {
  list-style: 24px;
  font-size: 20px;
}

.user-bonus-popup__info, .user-bonus-popup__requirements p {
  font-size: 13px;
  line-height: 15px;
}

.writer__header h1 {
  font-size: 1.5em;
}

#piano-zamek-za-registraci-custom {
  position: relative;
}

#piano-zamek-za-registraci-custom:empty {
  display: none;
}

#piano-zamek-za-registraci-custom:before {
  content: "";
  background: linear-gradient(rgba(255, 255, 255, .5) 0%, #fff 100%);
  width: 100%;
  height: 142px;
  display: block;
  position: absolute;
  top: -142px;
}

.piano-lock__placeholder {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: flex;
}

.piano-lock__title {
  text-align: center;
  margin: 0;
}

.piano-lock__button {
  margin: 0 !important;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #1e1e1e;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:after, .gallery-main-container:before, .gallery-vertical-container:after, .gallery-vertical-container:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(30, 30, 30, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

.enquiry-container .question {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
}

.enquiry-container .form-field input {
  float: left;
  margin-top: 2px;
}

.enquiry-container .form-field label {
  padding: 7px 0 15px 20px;
  font-size: 13px;
  display: block;
}

.enquiry-container .form-field label:hover {
  color: #d21e1e;
  transition: all .2s;
}

.enquiry-container .form-field .button-main {
  text-align: center;
  margin: auto;
  display: block;
}

.enquiry-container .form-field .thank {
  text-align: center;
  color: #aaa;
  margin: 15px auto 0;
  font-size: 16px;
  font-weight: 300;
  display: none;
}

.enquiry-container .form-field .graph {
  clear: both;
  display: none;
}

.enquiry-container .form-field .graph .graph-value {
  float: left;
  background-color: #d21e1e;
  border-radius: 2px;
  height: 12px;
  margin-top: 3px;
  display: block;
  position: relative;
}

.enquiry-container .form-field .graph .value-perc {
  color: #d21e1e;
  padding: 0 8px;
  font-weight: 700;
  line-height: 100%;
}

.enquiry-container.voted .form-field .button-main {
  display: none;
}

.enquiry-container.voted .form-field input[type="radio"] {
  display: none;
}

.enquiry-container.voted .form-field label:before, .enquiry-container.voted .form-field label:after {
  content: none;
}

.enquiry-container.voted .form-field label {
  padding-bottom: 0;
  padding-left: 0;
}

.enquiry-container.voted .form-field label:hover {
  color: #1e1e1e;
}

.enquiry-container.voted .form-field .your-vote {
  color: #d21e1e;
}

.enquiry-container.voted .form-field .your-vote:hover {
  color: #d21e1e;
  transition: all .2s;
}

.enquiry-container.voted .form-field .graph, .enquiry-container.voted .form-field .thank {
  display: block;
}

.enquiry-container.darkenquiry .question, .enquiry-container.darkenquiry .form-field {
  color: #fff;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 200000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, arial, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, arial, sans-serif;
  font-size: 26px;
  line-height: 36px;
}

.cookie-bar__title br {
  display: none;
}

.cookie-bar__text {
  padding: 5px 0 10px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: inline;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 15px 10px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 0;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

#gdpr-form textarea {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #a9a9a9;
  width: 100%;
  padding: 2px 5px;
}

#gdpr-form .gdpr-form__btns-wrapper {
  text-align: left;
}

#gdpr-form .gdpr-form__btns-wrapper .login-button {
  text-align: center;
  vertical-align: unset;
  min-width: 93px;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
}

.enquiry-main-container {
  margin-bottom: 30px;
}

.enquiry-main-container .enquiry-block {
  color: red;
  text-transform: uppercase;
  border-bottom: 1px solid red;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 30px;
}

.enquiry-main-container .enquiry-container .question {
  margin: 15px 0 20px;
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
}

.enquiry-main-container .enquiry-container .form-field input {
  display: none;
}

.enquiry-main-container .enquiry-container .form-field input:checked + label:after {
  background-color: red;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.enquiry-main-container .enquiry-container .form-field label {
  padding: 5px 0 20px 40px;
  font-size: 15px;
  line-height: 19.5px;
  display: block;
  position: relative;
}

.enquiry-main-container .enquiry-container .form-field label:hover:after {
  background-color: red;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.enquiry-main-container .enquiry-container .form-field label:before, .enquiry-main-container .enquiry-container .form-field label:after {
  content: "";
  background-clip: padding-box;
  display: block;
}

.enquiry-main-container .enquiry-container .form-field label:before {
  border: 2px solid #d3e6ef;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.enquiry-main-container .enquiry-container .form-field .button-main {
  text-align: center;
  color: #fff;
  background: red;
  width: 100px;
  margin: 30px auto 0;
  padding: 10px 30px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 15px;
  display: block;
}

.enquiry-main-container .enquiry-container .form-field .thank {
  text-align: left;
  color: #707b87;
  margin: 15px auto 0;
  font-size: 16px;
  font-weight: 300;
  display: none;
}

.enquiry-main-container .enquiry-container .form-field .graph {
  margin-bottom: 15px;
}

.enquiry-main-container .enquiry-container .form-field .graph .graph-value {
  background-color: red;
  border-radius: 0;
  height: 15px;
  margin-top: 0;
}

.enquiry-main-container .enquiry-container .form-field .graph .value-perc {
  color: #16212d;
  padding: 0 8px;
  font-weight: 700;
  line-height: 100%;
}

.enquiry-main-container .enquiry-container.voted .form-field .button-main {
  display: none;
}

.enquiry-main-container .enquiry-container.voted .form-field input[type="radio"] {
  display: none;
}

.enquiry-main-container .enquiry-container.voted .form-field label:before, .enquiry-main-container .enquiry-container.voted .form-field label:after {
  content: none;
}

.enquiry-main-container .enquiry-container.voted .form-field label {
  padding-bottom: 3px;
  padding-left: 0;
}

.enquiry-main-container .enquiry-container.voted .form-field label:hover {
  color: #16212d;
}

.enquiry-main-container .enquiry-container.voted .form-field .your-vote {
  color: red;
}

.enquiry-main-container .enquiry-container.voted .form-field .your-vote:hover {
  color: red;
  transition: all .2s;
}

.enquiry-main-container .enquiry-container.voted .form-field .graph, .enquiry-main-container .enquiry-container.voted .form-field .thank {
  display: block;
}

.enquiry-main-container .enquiry-container.darkenquiry .question, .enquiry-main-container .enquiry-container.darkenquiry .form-field {
  color: #fff;
}

.box-container.darkenquiry {
  background-color: #16212d;
}

.enquiryArticle #thumbup {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.enquiryArticle #thumbdown {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}

.enquiryArticle .enqArtMiddle {
  height: 41px;
}

.enquiryArticle .enqArtVotes .votesArtFirstChoice {
  float: left;
  color: #91c652;
  width: 48%;
  font-size: 12px;
}

.enquiryArticle .enqArtVotes .votesArtSecondChoice {
  float: right;
  color: red;
  text-align: right;
  width: 48%;
  font-size: 12px;
}

.enquiryArticle .enqArtBar {
  z-index: 0;
  background: #91c652;
  width: 500px;
  height: 41px;
  padding: 0;
  line-height: 41px;
  position: absolute;
  top: 0;
  left: 20px;
}

.enquiryArticle .enqArtBar .percArtFirstChoice, .enquiryArticle .enqArtBar .percArtSecondChoice {
  color: #fff;
  height: 41px;
  line-height: 41px;
}

.enquiryArticle .enqArtBar .percArtFirstChoice {
  float: left;
  background: #91c652;
  min-width: 10%;
  max-width: 80%;
  padding-left: 5%;
}

.enquiryArticle .enqArtBar .percArtSecondChoice {
  float: right;
  text-align: right;
  background: red;
  min-width: 10%;
  max-width: 80%;
  padding-right: 5%;
}

.enquiryArticle .answers .answer1 {
  float: left;
  width: 48%;
}

.enquiryArticle .answers .answer1 .enqArtFirstChoice {
  color: #91c652;
}

.enquiryArticle .answers .answer2 {
  float: right;
  text-align: right;
  width: 48%;
}

.enquiryArticle .answers .answer2 .enqArtSecondChoice {
  color: red;
}

.enquiryArticle .enqArtThanks {
  margin-top: 50px;
  display: none;
}

.articleText .enquiry-bars__question, .article-listed .enquiry-bars__question, .enquiry-bars__question {
  float: none;
  margin: 0 0 24px;
  font-family: Times New Roman, serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
}

.articleText .enquiry-bars-result-notice__link, .article-listed .enquiry-bars-result-notice__link, .enquiry-bars-result-notice__link {
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #d21e1e;
  border-radius: 4px;
  width: 142px;
  height: 40px;
  margin: 24px auto 0;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
}

.enquiry-bars {
  clear: both;
  background-color: #fff;
  padding: 15px;
}

.enquiry-bars__title {
  color: #5c6773;
  border-top: 4px solid #d21e1e;
  margin-bottom: 24px;
  padding-top: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.enquiry-bars-answers__answer {
  background-color: #f9dfdf;
  border-left: 4px solid #d21e1e;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
}

.enquiry-bars-answers__answer:last-child {
  margin-bottom: 24px;
}

.enquiry-bars-answers__answer--hover:hover {
  cursor: pointer;
  border: 1px solid #a81919;
  border-left-width: 4px;
}

.enquiry-bars-answers__answer--hover:hover .enquiry-bars-answer__statement {
  padding: 7px 15px 7px 16px;
}

.enquiry-bars-answer__indicator {
  background-color: #f6b3bf;
  width: 0;
  height: 44px;
  transition: width 1s linear;
  position: absolute;
}

@keyframes enquiry-bars-indicator-slide {
  from {
    width: 0;
  }

  to {
    width: var(--to-width);
  }
}

.enquiry-bars-answer__statement {
  justify-content: space-between;
  padding: 8px 16px;
  display: flex;
  position: relative;
}

.enquiry-bars-answer__statement--selected {
  font-weight: 700;
}

.enquiry-bars__result-notice {
  background-color: #f9dfdf;
  margin-bottom: 24px;
  padding: 24px 32px;
}

.enquiry-bars-result-notice__text {
  text-align: center;
  font-family: arial, "sans-serif";
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.enquiry-bars__footer {
  border-bottom: 1px solid #ccd5de;
  justify-content: space-between;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
}

.enquiry-bars-footer__vote-notice {
  color: #d21e1e;
  font-weight: 700;
}

.enquiry-bars-footer__total-votes {
  text-align: right;
  color: #5c6773;
  margin-left: auto;
}

.enquiry-bars-result-notice__text {
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.articleText .enquiry-bars__question, .article-listed .enquiry-bars__question {
  font-size: 25px;
  line-height: 30px;
}

.articleText .enquiry-bars-result-notice__link, .article-listed .enquiry-bars-result-notice__link {
  border-radius: 0;
  width: 160px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 14px;
}

#paid-zone a.link--red {
  color: red;
}

.icon-premium {
  z-index: 1;
  box-sizing: border-box;
  color: #403014;
  text-align: center;
  background-color: #e8c157;
  width: 80px;
  height: 20px;
  padding: 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-premium.small {
  width: 76px;
  height: 16px;
}

.icon-premium.small .icon-premium__fallback {
  position: relative;
  top: -2px;
}

.icon-premium__x {
  color: #e50031;
}

.icon-premium__fallback {
  font-family: arial, sans-serif;
  line-height: 1;
}

.icon-premium__link {
  color: #403014;
  width: 100%;
  height: 100%;
}

.dataimg-icon-premium {
  z-index: 1;
  background-position: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.premium-x {
  background: #f8f8f9;
  margin: 0 0 30px;
}

.premium-x .subscription {
  width: 530px;
  margin: 0 auto;
}

.premium-x .subscription__headline {
  width: 95%;
}

.premium-x.recentarticles-premium {
  background: none;
  margin: 0;
}

.premium-x__archive {
  text-align: center;
}

.premium-x__archive-issue {
  width: 300px;
  margin: 0 0 20px;
}

.premium-x__archive-button {
  width: 230px;
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
}

.subscription-faq {
  background: #fff;
  border: 1px solid #d1d4e4;
  margin: 15px 0;
  padding: 15px;
}

.subscription-faq__headline {
  margin: 10px 0 30px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.subscription-faq__question {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 15px;
  padding: 0 15px 15px 0;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question:hover {
  cursor: pointer;
}

.subscription-faq__question.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after {
  transform: rotate(0);
}

.breadcrumbs-paid-zone {
  margin: -35px 0 20px;
}

#paid-zone {
  clear: both;
  color: #2c2f3b;
  box-sizing: border-box;
  background: #fff;
  width: 590px;
  margin: 0 auto 30px;
  padding: 15px;
  position: relative;
}

#paid-zone table {
  width: 100%;
}

#paid-zone a, #paid-zone .back-to-hp {
  color: #2c2f3b;
  text-decoration: underline;
}

#paid-zone a:hover, #paid-zone .back-to-hp:hover {
  text-decoration: none;
}

#paid-zone ul {
  padding: 0;
}

#paid-zone ul.bullet-list {
  margin: -5px 0 15px;
}

#paid-zone ul.bullet-list li {
  padding-left: 17px;
  line-height: 27px;
  list-style-type: none;
  position: relative;
}

#paid-zone ul.bullet-list li:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
}

#paid-zone .align-center {
  text-align: center;
}

#paid-zone .error {
  color: red;
}

#paid-zone .success {
  color: #65c41e;
}

#paid-zone .grey-highlight {
  color: #aaa;
}

#paid-zone .table {
  border-top: 1px solid #d1d4e4;
  padding: 20px 0;
}

#paid-zone .table td {
  vertical-align: top;
  border: none;
  padding: 7px 0;
  line-height: 22px;
  position: relative;
}

#paid-zone .table label {
  margin: 0;
}

#paid-zone .user-bonuses + .table {
  border-top: none;
}

#paid-zone .profile-info .table + .table {
  padding-bottom: 4px;
}

#paid-zone .main-info {
  text-align: left;
  border: none;
  margin: 0;
  padding: 0;
}

#paid-zone .main-info h1 {
  color: red;
  text-align: left;
  font-family: arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .main-info h1.align-center {
  text-align: left;
}

#paid-zone .main-info p {
  text-align: left;
  margin: 25px 0 15px;
  font-size: 14px;
  font-weight: bold;
}

#paid-zone .info-text {
  border-bottom: 1px solid #d1d4e4;
  margin-top: -10px;
  margin-bottom: 30px;
  padding-bottom: 25px;
  line-height: 18px;
}

#paid-zone .info-text p {
  font-size: 12px;
}

#paid-zone .prihlaseni-info {
  float: right;
  margin: 40px 0 30px;
}

#paid-zone .logout {
  color: #2c2f3b;
  text-align: right;
  text-decoration: underline;
  position: absolute;
  top: 20px;
  right: 15px;
}

#paid-zone .logout:hover {
  text-decoration: none;
}

#paid-zone .order-form .form-field {
  margin: 0;
}

#paid-zone .backlink {
  margin: 15px 0;
}

#paid-zone .backlink.tos-links {
  margin-bottom: 20px;
}

#paid-zone .backlink.tos-links a:not(.main-button) {
  color: #2c2f3b;
}

#paid-zone .backlink div + .link-basic {
  margin-top: 10px;
}

#paid-zone .backlink h3 {
  font-family: arial, sans-serif;
  font-size: 13px;
}

#paid-zone .backlink h3 a {
  color: #2c2f3b;
  font-weight: normal;
  text-decoration: underline;
}

#paid-zone .backlink h3 a:hover {
  text-decoration: none;
}

#paid-zone .main-button, #paid-zone .button-secondary {
  color: #fff;
  background: red;
  border: none;
  border-radius: 2px;
  min-width: auto;
  height: 34px;
  margin: 0 auto;
  padding: 0 15px;
  font-family: arial, Verdana, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

#paid-zone .main-button:hover, #paid-zone .button-secondary:hover {
  color: #fff;
  text-decoration: underline;
}

#paid-zone .button-secondary {
  color: #2c2f3b;
  background: #d1d4e4;
}

#paid-zone .button-secondary:hover {
  background: #9aa0c4;
  transition: all .3s;
}

#paid-zone .main-button + .button-secondary {
  margin-left: 12px;
}

#paid-zone .subscription-sidenote {
  background: #fcf7db;
  margin: -5px 0 20px;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__type-order {
  color: #fff;
}

#paid-zone .well-content {
  margin: 0;
  padding: 0;
}

#paid-zone .well-content.info-text {
  border-top: 1px solid #d1d4e4;
  margin: 20px 0;
  padding: 20px 0;
}

#paid-zone #activation-activate .align-center.red {
  margin-bottom: 10px;
}

#paid-zone #activation-activate .default-form input.currency-input {
  text-align: center;
  height: 28px;
  line-height: 34px;
}

#paid-zone #activation-activate .default-form .prihlasit.main-button {
  height: 34px;
  line-height: 34px;
}

#paid-zone #activation-activate .well-content.info-text ol {
  margin: 10px 0 0 20px;
  list-style: decimal;
}

#paid-zone #activation-activate .well-content.info-text ol li {
  margin: 10px 0;
  font-size: 12px;
}

#paid-zone .subscription-notice {
  background: #eef4f7;
  width: 64%;
  margin: 25px 0 20px;
  line-height: 18px;
}

#paid-zone .subscription-notice p {
  padding: 10px;
}

#paid-zone .subscription__icon {
  height: 21px;
  margin: 0 0 0 6px;
  display: inline-block;
}

#paid-zone .subscription-faq, #paid-zone .subscription-faq-100 .subscription-faq {
  width: 570px;
}

#paid-zone .back-to-hp {
  margin-top: 15px;
  display: inline-block;
}

#paid-zone .dataimg-icon-premium-master-card {
  width: 26px;
}

#paid-zone .dataimg-icon-premium-visa {
  width: 29px;
}

#paid-zone .dataimg-icon-premium-gopay {
  width: 50px;
}

#paid-zone .dataimg-icon-premium-sms {
  width: 19px;
  position: relative;
  top: -1px;
}

#paid-zone #subscription #subscription-offer {
  width: 100%;
  margin: 0;
  padding: 0;
}

#paid-zone #subscription #subscription-offer ul.subscription__payment-options-bullets {
  margin-top: 0;
  position: relative;
  top: 3px;
}

#paid-zone #subscription #subscription-offer ul.subscription__payment-options-bullets li {
  margin: 0 0 0 10px;
  padding: 0 5px 0 0;
  display: flex;
}

#paid-zone #subscription #subscription-offer .subscription__icon:not(.dataimg-payment-sms) {
  margin: -2px 0 0;
}

#paid-zone #subscription #subscription-offer .subscription__types {
  flex-wrap: wrap;
  display: flex;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type {
  width: 245px;
  padding-bottom: 65px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type p.subscription__type-note br {
  display: none;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-order {
  width: 89%;
  position: absolute;
  bottom: 15px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher {
  background: #f8f8f8;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher .subscription__type-title {
  margin-right: 20px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher .subscription__type-order {
  width: 170px;
  margin-right: 50px;
  position: static;
}

#paid-zone .subscription__voucher .subscription__voucher-title {
  padding: 0 0 20px;
  font-size: 17px;
  line-height: 22px;
  display: block;
}

#paid-zone .subscription__voucher .subscription__voucher-preview {
  width: 100%;
  margin: 0 0 20px;
}

#paid-zone .subscription__voucher .order__success-pdf {
  color: #fff;
  text-align: center;
  background: red;
  width: 260px;
  margin: 0 auto;
  padding: 11px 15px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
}

#paid-zone .subscription__voucher .order__success-howto h2 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#subscription-login .tos-links {
  width: 600px;
  margin: 0 auto 20px;
}

#activation-login #login-wrapper {
  margin: 30px 0 35px;
}

#blesk-login #login-wrapper .main-container .login-close {
  top: -45px;
  right: -15px;
}

#blesk-login #login-wrapper #paid-zone {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  position: static;
}

#blesk-login #login-wrapper #paid-zone .tos-links {
  width: 100%;
}

#order-subscription {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

#order-subscription .table .payment input {
  margin: 0 10px 0 0;
}

#order-subscription .table .payment td {
  padding: 8px 0;
}

#order-subscription .table .payment .table-header {
  padding-bottom: 10px;
}

#order-subscription .table .payment-row td {
  padding-bottom: 2px;
}

#order-subscription .subscription__note {
  width: 48%;
}

#order-subscription .payment-total {
  width: 100%;
  margin: 10px 0 25px;
  font-size: 18px;
  line-height: 21px;
}

#order-subscription .agreement {
  border-top: 1px solid #d1d4e4;
  padding-top: 25px;
  line-height: 20px;
}

#order-subscription .agreement a {
  color: #2c2f3b;
  text-decoration: underline;
}

#order-subscription .agreement a:hover {
  text-decoration: none;
}

#order-subscription .agreement .with-error, #order-subscription .agreement .with-error a {
  color: red;
}

#order-subscription .agreement .error-message {
  color: red;
  margin-top: 5px;
  margin-left: 22px;
  font-weight: bold;
  display: block;
}

#order-subscription .logout {
  top: 6px;
  right: 0;
}

#form-subscribe {
  text-align: center;
}

form.default-form .discount-code {
  padding-bottom: 15px;
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code .discount-code__label {
  margin: 0;
  display: block;
}

form.default-form .discount-code .discount-code__input {
  color: #000001;
  border: 1px solid #d1d4e4;
  border-radius: 3px;
  outline: none;
  width: 180px;
  margin: 10px 5px 10px 0;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: inline-block;
}

form.default-form .discount-code .discount-code__button {
  color: #0000ca;
  cursor: pointer;
  background: none;
  border: none;
  font-weight: 700;
  display: inline-block;
}

form.default-form .discount-code .discount-code__error {
  color: #e00a17;
}

form.default-form .price-note {
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code__price {
  color: #e00a17;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

#order-subscription-sms {
  width: 100%;
  margin: 0 auto;
  line-height: 18px;
}

#order-subscription-sms h1 {
  margin-bottom: 25px;
}

#order-subscription-sms ul.bullet-list {
  margin-top: 8px;
}

#order-subscription-sms #steps {
  border-bottom: 1px solid #d1d4e4;
  margin: 12px 0 25px;
  padding: 0 0 25px;
}

#order-subscription-sms #steps .for-1:before {
  content: "1.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-2:before {
  content: "2.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-3:before {
  content: "3.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps li {
  margin: 25px 0;
  padding: 0 0 0 18px;
  list-style-type: none;
  position: relative;
}

#order-subscription-sms #steps li:first-child {
  margin-top: 0;
  padding-right: 10px;
}

#order-subscription-sms #steps li:last-child {
  margin-bottom: 0;
}

#order-subscription-sms #steps li form {
  margin-top: 10px;
  position: relative;
}

#order-subscription-sms #steps li form div {
  position: absolute;
}

#order-subscription-sms #steps li form span {
  color: #aaa;
  font-size: 13px;
  font-weight: 700;
  line-height: 0;
  display: block;
  position: absolute;
  top: 24px;
  left: 10px;
}

#order-subscription-sms #steps li .error-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
  position: relative;
}

#order-subscription-sms #steps li .sms_input {
  float: left;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 92px;
  height: 34px;
  margin: 5px 10px 0 0;
  padding: 0 0 0 44px;
  font-size: 13px;
}

#order-subscription-sms #steps li .sms_input.error {
  border-color: red;
}

#order-subscription-sms #steps li .main-button {
  height: 36px;
  margin: 5px 0 0;
  line-height: 36px;
}

#order-subscription-sms #additional-info h2 {
  margin-bottom: 18px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

#order-subscription-sms #additional-info .subscription-sidenote {
  margin: 30px 0;
}

#activation-login.not-subscribed .main-info p {
  float: left;
  width: 75%;
  margin: 18px 0 30px;
  line-height: 21px;
}

#activation-login.not-subscribed .prihlaseni-info {
  margin: 21px 0 30px;
}

#activation-login.not-subscribed .subscription {
  clear: both;
}

#activation-login .recurring-date #recurring-info {
  color: red;
  cursor: pointer;
  padding: 0 2px;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  position: relative;
}

#activation-login .recurring-date #recurring-info #recurring-info-popup {
  text-align: left;
  color: #2c2f3b;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  width: 160px;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

#paid-zone .tos-links {
  width: 570px;
  margin-top: 25px;
}

#paid-zone #login-wrapper .main-container {
  box-shadow: none;
  text-align: center;
  width: 570px;
  margin: 0;
  padding: 25px;
  display: block;
}

#paid-zone #login-wrapper .main-container a {
  color: #fff;
  background: #ed1c24;
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

#paid-zone #login-wrapper .main-container a:hover {
  background: #a60c12;
  text-decoration: none;
}

#paid-zone #login-wrapper .main-container .login-tabs {
  width: 282px;
  left: -1px;
}

#paid-zone #login-wrapper .main-container .login-tabs a.active {
  border: 1px solid #ccc;
  border-bottom: 0;
}

#paid-zone #login-wrapper .social-networks .button-facebook {
  width: 245px;
}

#paid-zone #login-wrapper .form-row .info {
  width: 230px;
}

.cnc-modal {
  z-index: 99999;
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.cnc-modal-body {
  height: 690px;
}

.cnc-modal-open {
  overflow: hidden;
}

.cnc-modal-content {
  width: auto;
  max-width: 640px;
  margin: 30px auto;
  padding: 0;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
}

.cnc-modal-close {
  position: absolute;
  top: 20px;
  right: 45px;
}

.cnc-modal-close .dataimg-cross {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.cnc-modal-close .dataimg-cross svg {
  height: 20px;
}

.cnc-modal-close .dataimg-cross svg path {
  fill: #16212d;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@media (max-width: 340px) {
  .cnc-modal-close {
    right: 28px;
  }
}

@media (max-width: 430px) {
  .cnc-modal-close {
    right: 33px;
  }
}

.activation-steps {
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  display: flex;
}

.activation-step {
  color: #2e2e2e;
  background: #ececec;
  width: calc(50% - 55px);
  padding: 9px 20px 8px;
  font-weight: 700;
  position: relative;
}

.activation-step:last-of-type {
  text-indent: 25px;
  max-width: calc(50% - 30px);
  left: -30px;
}

.activation-step:before {
  content: "";
  z-index: 1;
  border: 35px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #ececec;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.activation-step.active-step {
  color: red;
  background: #fafafa;
}

.activation-step.active-step:before {
  border-left: 30px solid #fafafa;
}

.activation-steps__step {
  font-size: 15px;
  line-height: 25px;
}

.activation-steps__info {
  font-size: 17px;
  line-height: 28px;
}

.trhak-premium {
  display: flex;
}

.trhak-premium__image {
  margin-right: 15px;
}

.trhak-activate .currency-input {
  width: 60%;
}

.trhak-activate .prihlasit.main-button {
  margin-top: 15px;
}

.trhak-activate .error-message {
  color: red;
  margin-top: 15px;
  display: block;
}

.trhak-activate #code2 {
  width: 35%;
}

.trhak-activate__image {
  margin-right: 25px;
}

.trhak.info-text {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.trhak-headline {
  margin: -15px 0 15px;
  display: block;
}

@media screen and (max-width: 640px) {
  .trhak-premium {
    display: block;
  }

  .trhak-premium__image {
    float: left;
    width: 75px;
    margin-top: 10px;
  }

  .trhak-activate__image {
    width: 70px;
    margin-right: 10px;
  }

  .trhak-headline {
    margin-top: -30px;
    line-height: 18px;
  }

  #paid-zone #activation-activate .trhak-premium.info-text ol {
    padding: 0;
  }

  #paid-zone #activation-activate .trhak-activate #form-activate .currency-input {
    width: calc(100% - 12px);
    margin-bottom: 10px;
    padding: 5px;
    font-size: 12px;
  }
}

#paid-zone {
  font-size: 13px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:after {
  top: 3.75px;
  left: 3.2px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:before {
  border-width: 1px;
  border-color: #aaa;
  top: 1px;
}

#paid-zone form.default-form .form-field input[type="checkbox"] + label:before {
  border-width: 1px;
  border-color: #aaa;
}

#paid-zone .red {
  color: red;
}

#paid-zone .table {
  clear: both;
}

#paid-zone .main-button {
  line-height: 36px;
}

#paid-zone .subscription {
  box-sizing: border-box;
  width: 690px;
  margin: 0 auto 20px -15px;
  padding: 30px 0;
}

#paid-zone .subscription a {
  color: red;
}

#paid-zone .subscription .subscription__type-order {
  color: #fff;
  text-decoration: none;
}

#paid-zone .subscription .subscription__type-order--white {
  color: red;
  background-color: #fff;
}

#paid-zone .subscription .subscription__type-order--white:hover {
  color: #fff;
  background-color: red;
}

#paid-zone .tos-links {
  border-top: 1px solid #d1d4e4;
  margin: 30px 0 20px;
  padding: 30px 0 1px;
}

#paid-zone .tos-links .title {
  text-align: left;
  margin-bottom: 0;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

#paid-zone .tos-links .title a {
  color: #2c2f3b;
  text-decoration: underline;
}

#paid-zone .tos-links .title a:hover {
  text-decoration: none;
}

#paid-zone .tos-links .link-basic {
  padding-left: 17px;
  position: relative;
}

#paid-zone .tos-links .link-basic:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.recentartlices-premium-celebrity {
  z-index: 10;
  width: 640px;
  margin-top: 40px;
  margin-left: -40px;
  padding: 0 40px 25px;
  position: relative;
}

.recentartlices-premium-celebrity .title-basic {
  margin-top: 0;
}

.recentartlices-premium-celebrity a {
  text-decoration: none !important;
}

.recentartlices-premium-celebrity .artPaid {
  z-index: 1;
  width: 97px;
  height: 21px;
  position: absolute;
  left: 0;
}

.recentartlices-premium-celebrity .recent-articles__next-articles {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.recentartlices-premium-celebrity .next-articles {
  text-align: center;
  color: #fff;
  background: red;
  border-radius: 2px;
  width: 214px;
  height: 30px;
  padding: 12px 26px 10px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  display: inline;
  text-decoration: none !important;
}

#recentarticles-premium-pane .recentartlices-premium-celebrity {
  margin-left: -50px;
}

.subscription-faq {
  background: #fff;
  width: 570px;
  margin: 20px auto;
  padding: 15px;
}

.subscription-faq__headline {
  margin: 10px 0 30px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.subscription-faq__questions {
  padding: 0;
}

.subscription-faq__answer {
  margin-top: 0;
  display: none;
}

.subscription-faq__answer:hover {
  cursor: default;
}

.subscription-faq__answer p {
  margin: 15px 0 0;
}

.subscription-faq__contact {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
}

.subscription-faq__question, .subscription-types-switcher {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 12px;
  padding: 0 15px 12px 0;
  font-size: 13px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after, .subscription-types-switcher:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question.active .subscription-faq__answer, .subscription-types-switcher.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after, .subscription-types-switcher.active:after {
  transform: rotate(0);
}

.subscription-faq__question strong, .subscription-types-switcher strong {
  display: block;
}

.subscription-faq__question strong:hover, .subscription-types-switcher strong:hover {
  cursor: pointer;
}

.subscription-types-switcher {
  border-bottom: 0;
  font-size: 13px;
}

.subscription-types-switcher:after {
  filter: invert(15%) sepia(82%) saturate(5998%) hue-rotate(358deg) brightness(105%) contrast(115%);
  top: 5px;
}

.subscription-types-switcher__holder {
  text-align: center;
  margin-top: 5px;
}

.subscription-types-switcher__holder ~ div, .subscription-types-switcher__holder ~ ul {
  display: none;
}

.subscription-types-switcher__holder.active ~ div, .subscription-types-switcher__holder.active ~ ul {
  display: block;
}

.subscription-types-switcher__holder strong:before {
  content: "Zobrazit";
}

.subscription-types-switcher__holder.active strong:before {
  content: "Skrýt";
}

.subscription-types-switcher__holder.active .subscription-types-switcher:after {
  transform: rotate(0);
}

@media only screen and (max-width: 480px) {
  .subscription-faq__contact a {
    text-decoration: underline;
  }

  .subscription-faq__contact a:hover, .subscription-faq__contact a:active {
    text-decoration: none;
  }
}

#blesk-premium-corporate {
  width: 620px;
  margin: 0 auto 30px;
  padding: 30px 0;
  font-family: arial, sans-serif;
}

#blesk-premium-corporate .header {
  text-align: center;
}

#blesk-premium-corporate .header .svg-title svg {
  width: auto;
  height: 45px;
}

#blesk-premium-corporate .header .headline, #blesk-premium-corporate .header .subtitle {
  text-align: center;
  float: left;
  width: 100%;
}

#blesk-premium-corporate .header .headline {
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

#blesk-premium-corporate .header .subtitle {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 17px;
}

#blesk-premium-corporate .body .ttFormCorporateSubscription {
  border-top: 1px solid #cad5df;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 15px;
}

#blesk-premium-corporate .body .ttFormCorporateSubscription .subscription-success-message {
  text-align: center;
  z-index: 1000;
  color: red;
  background: #fff;
  border: 1px solid red;
  border-radius: 5px;
  width: 620px;
  max-width: 90%;
  padding: 15px 0;
  font-size: 17px;
  display: block;
  position: fixed;
  top: 50%;
  box-shadow: 0 7px 24px rgba(0, 0, 0, .5);
}

#blesk-premium-corporate .body .form-field label, #blesk-premium-corporate .body .form-field .error-message {
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

#blesk-premium-corporate .body .form-field .error-message {
  color: red;
  text-align: left;
  font-size: 10px;
  font-weight: bold;
  display: none;
}

#blesk-premium-corporate .body .form-field.with-error .error-message {
  display: block;
}

#blesk-premium-corporate .body .subscription-type {
  float: left;
  cursor: pointer;
  background-color: #fff;
  width: 190px;
  height: 110px;
  margin-right: 25px;
  display: block;
}

#blesk-premium-corporate .body .subscription-type.selected {
  border: 1px solid red;
  width: 188px;
  height: 108px;
}

#blesk-premium-corporate .body .subscription-type:last-child {
  margin-right: 0;
}

#blesk-premium-corporate .body .subscription-type .main {
  margin: 10px;
  position: relative;
}

#blesk-premium-corporate .body .subscription-type .main input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

#blesk-premium-corporate .body .subscription-type .main label {
  float: right;
  width: 80%;
  max-height: 17px;
  margin: 7px 0 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
}

#blesk-premium-corporate .body .subscription-type .main .price {
  color: red;
  float: right;
  width: 80%;
  margin-top: 15px;
  font-size: 30px;
  line-height: 30px;
}

#blesk-premium-corporate .body .subscription-type .main .price .price__currency {
  font-size: 20px;
}

#blesk-premium-corporate .body .subscription-type .main .dph {
  float: right;
  width: 80%;
  font-size: 13px;
  line-height: 20px;
}

#blesk-premium-corporate .body .subscription-type .customRadio .checkmark {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 11px;
  left: 7px;
}

#blesk-premium-corporate .body .subscription-type .customRadio .checkmark:after {
  content: "";
  background: #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  top: 2px;
  left: 2px;
}

#blesk-premium-corporate .body .subscription-type .customRadio input:checked ~ .checkmark:after {
  display: block;
}

#blesk-premium-corporate .body input {
  color: #000;
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 12px 10px 11px;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

#blesk-premium-corporate .body input::placeholder {
  color: #aaa;
  opacity: 1;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
}

#blesk-premium-corporate .body .email label {
  margin-top: 15px;
}

#blesk-premium-corporate .body .email input {
  width: 292px;
  margin-top: 10px;
}

#blesk-premium-corporate .body .message {
  margin: 25px 0;
}

#blesk-premium-corporate .body .message #message {
  color: #000;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 598px;
  height: 117px;
  min-height: 117px;
  margin-top: 10px;
  padding: 13px 11px 12px;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

#blesk-premium-corporate .body .message #message::placeholder {
  color: #aaa;
  opacity: 1;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
}

#blesk-premium-corporate .body .captcha label {
  margin-bottom: 6px;
}

#blesk-premium-corporate .body .captcha input {
  width: 122px;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row .submit-mob-clear {
  clear: initial;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row div.captcha-img {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 2px;
  max-height: 40px;
  margin-right: 15px;
  padding: 4px;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row input.submit {
  float: right;
  color: #fff;
  background-color: red;
  border: 0;
  border-radius: 2px;
  width: 190px;
  max-height: 40px;
  padding: 12px 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}

#blesk-premium-corporate .body .corporate-order {
  text-align: center;
  border-bottom: 1px solid #cad5df;
  padding-top: 15px;
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 20px;
}

.paid-zone__corporate-activation #paid-zone {
  box-sizing: border-box;
  width: 600px;
}

.paid-zone__corporate-activation #paid-zone .content {
  background-color: #fff;
}

.paid-zone__corporate-activation #paid-zone .content .sub-wrapper .main-info p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}

.paid-zone__corporate-activation #paid-zone .content .sub-wrapper #subscription-login {
  margin-bottom: -15px;
  margin-left: -15px;
}

.paid-zone__corporate-activation #paid-zone a.main-button {
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 38px;
}

.paid-zone__corporate-activation #paid-zone p {
  font-size: 14px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone header.main-info {
  text-align: center;
  background-color: #fff;
  padding-bottom: 0;
}

.paid-zone__corporate-activation #paid-zone header.main-info h1 {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
  padding-bottom: 15px;
  font-family: arial, sans-serif;
  font-size: 25px;
  line-height: 32px;
}

.paid-zone__corporate-activation #paid-zone header.main-info h1.align-center {
  text-align: center;
}

.paid-zone__corporate-activation #paid-zone .info-text {
  background: #fff;
  border: 0;
  margin: 15px 0 0;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.paid-zone__corporate-activation #paid-zone .info-text p {
  margin: 0 0 15px;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone .info-text ol {
  margin: 15px 0;
  padding: 0 0 0 15px;
  list-style: decimal;
}

.paid-zone__corporate-activation #paid-zone .info-text ol li {
  margin: 0 0 20px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone #login-wrapper {
  background: #e9eff4;
  width: 600px;
  padding: 30px 0 0;
}

.paid-zone__corporate-activation #paid-zone #login-wrapper .main-container {
  margin-bottom: -5px;
}

.paid-zone__corporate-activation #paid-zone #form-activate {
  margin-top: 18px;
  padding: 0 0 20px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field {
  width: 312px;
  margin: 0 auto 20px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field input[type="text"] {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid red;
  width: 200px;
  height: 42px;
  padding: 0 10px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 42px;
  display: inline-block;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field input[type="submit"] {
  color: #fff;
  float: right;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 113px;
  height: 42px;
  max-height: 40px;
  margin: 0 0 5px -5px;
  padding: 0 0 2px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field .error-message {
  float: left;
}

.paid-zone__corporate-activation .paid-zone__faq {
  box-sizing: border-box;
  width: 600px;
  margin: 40px auto;
}

.subscription--article {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 540px;
  padding: 48px 50px;
  position: relative;
}

@media (max-width: 750px) {
  .subscription--article {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.subscription--article.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(233, 239, 244, 0) 0 2%, #e9eff4 70%, #e9eff4);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.subscription--article * {
  box-sizing: border-box;
}

.subscription--article .subscription__logo {
  width: 100%;
  height: auto;
  display: flex;
}

.subscription--article .subscription__logo svg, .subscription--article .subscription__logo img {
  max-height: 16px;
}

.subscription--article .subscription__logo svg:first-child, .subscription--article .subscription__logo img:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

.subscription--article .subscription__logo svg:last-child, .subscription--article .subscription__logo img:last-child {
  opacity: .6;
}

.subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 26px;
  margin-bottom: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.subscription--article .subscription__description {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.subscription--article .subscription__button-wrapper {
  color: #fff;
}

.subscription--article .subscription__button-wrapper span, .subscription--article .subscription__button-wrapper .subscription__button--link {
  font-size: 12px;
}

.subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-right: 15px;
  display: inline-block;
}

.subscription--article .subscription__type-order {
  text-align: center;
  color: #fff;
  background-color: #f66533;
  border: none;
  border-radius: 0;
  width: 190px;
  height: 45px;
  margin: 20px 0 0;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
  display: block;
  position: static;
}

.subscription--article .subscription__type-order:hover {
  color: #fff;
}

.subscription--article .subscription__button--link {
  color: #fff;
  text-decoration: underline;
}

.subscription--article .subscription__button--link:hover {
  color: #fff;
  text-decoration: none;
}

#article .article-body .subscription--article, #article.listed .subscription--article {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 540px;
  padding: 48px 50px;
  position: relative;
}

@media (max-width: 750px) {
  #article .article-body .subscription--article, #article.listed .subscription--article {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

#article .article-body .subscription--article.fade-out:before, #article.listed .subscription--article.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(233, 239, 244, 0) 0 2%, #e9eff4 70%, #e9eff4);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

#article .article-body .subscription--article *, #article.listed .subscription--article * {
  box-sizing: border-box;
}

#article .article-body .subscription--article .subscription__logo, #article.listed .subscription--article .subscription__logo {
  width: 100%;
  height: auto;
  display: flex;
}

#article .article-body .subscription--article .subscription__logo svg, #article .article-body .subscription--article .subscription__logo img, #article.listed .subscription--article .subscription__logo svg, #article.listed .subscription--article .subscription__logo img {
  max-height: 16px;
}

#article .article-body .subscription--article .subscription__logo svg:first-child, #article .article-body .subscription--article .subscription__logo img:first-child, #article.listed .subscription--article .subscription__logo svg:first-child, #article.listed .subscription--article .subscription__logo img:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

#article .article-body .subscription--article .subscription__logo svg:last-child, #article .article-body .subscription--article .subscription__logo img:last-child, #article.listed .subscription--article .subscription__logo svg:last-child, #article.listed .subscription--article .subscription__logo img:last-child {
  opacity: .6;
}

#article .article-body .subscription--article .subscription__headline, #article.listed .subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 26px;
  margin-bottom: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

#article .article-body .subscription--article .subscription__description, #article.listed .subscription--article .subscription__description {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#article .article-body .subscription--article .subscription__button-wrapper, #article.listed .subscription--article .subscription__button-wrapper {
  color: #fff;
}

#article .article-body .subscription--article .subscription__button-wrapper span, #article .article-body .subscription--article .subscription__button-wrapper .subscription__button--link, #article.listed .subscription--article .subscription__button-wrapper span, #article.listed .subscription--article .subscription__button-wrapper .subscription__button--link {
  font-size: 12px;
}

#article .article-body .subscription--article .subscription__button-wrapper .subscription__type-order, #article.listed .subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-right: 15px;
  display: inline-block;
}

#article .article-body .subscription--article .subscription__type-order, #article.listed .subscription--article .subscription__type-order {
  text-align: center;
  color: #fff;
  background-color: #f66533;
  border: none;
  border-radius: 0;
  width: 190px;
  height: 45px;
  margin: 20px 0 0;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
  display: block;
  position: static;
}

#article .article-body .subscription--article .subscription__type-order:hover, #article.listed .subscription--article .subscription__type-order:hover {
  color: #fff;
}

#article .article-body .subscription--article .subscription__button--link, #article.listed .subscription--article .subscription__button--link {
  color: #fff;
  text-decoration: underline;
}

#article .article-body .subscription--article .subscription__button--link:hover, #article.listed .subscription--article .subscription__button--link:hover {
  color: #fff;
  text-decoration: none;
}

#article.listed .subscription--article {
  width: 100%;
}

#paid-zone hr {
  border: 0;
  border-top: 1px solid #d1d4e4;
  height: 0 !important;
}

#paid-zone label {
  font-family: Open Sans, arial, sans-serif;
  line-height: 1.6;
  display: inline;
}

#paid-zone input[type="checkbox"] {
  font-family: Open Sans, arial, sans-serif;
  line-height: 1.6;
  display: inline;
}

#paid-zone input {
  font-family: Open Sans, arial, sans-serif;
}

#paid-zone input[type="checkbox"] {
  margin-right: 10px;
}

#paid-zone strong {
  font-family: Open Sans Bold, arial, sans-serif;
}

#paid-zone h3 {
  margin: 0;
}

#paid-zone .main-button {
  text-transform: uppercase;
  cursor: pointer;
  height: 40px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 14px;
  line-height: 40px;
}

#paid-zone #activation-activate .default-form input#code {
  text-align: center;
  border: 1px solid #d1d4e4;
  height: 22px;
  padding: 5px 10px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

#paid-zone .main-info h1, #paid-zone .main-info p {
  font-family: Open Sans Bold, arial, sans-serif;
}

#paid-zone .tos-links .title {
  font-family: Open Sans, arial, sans-serif;
}

#paid-zone .tos-links .link-basic:before {
  top: 6px;
}

#paid-zone .logout {
  top: 0;
  right: 0;
}

#paid-zone .subscription {
  margin: 0 auto 20px -65px;
}

#paid-zone .subscription-faq__headline {
  margin-bottom: 25px;
}

#paid-zone .backlink h3 {
  font-family: Open Sans, arial, sans-serif;
}

#paid-zone .subscription-bpz + .backlink {
  margin-top: 50px;
}

#paid-zone #activation-login {
  position: relative;
}

#paid-zone #activation-login .recurring-date #recurring-info {
  color: red;
  cursor: pointer;
  padding: 0 2px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 15px;
  line-height: 15px;
  position: relative;
}

#paid-zone #activation-login .recurring-date #recurring-info #recurring-info-popup {
  text-align: left;
  color: #2c2f3b;
  z-index: 2;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  width: 160px;
  padding: 10px;
  font-family: Open Sans Light, arial, sans-serif;
  font-size: 13px;
  line-height: 17px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

#paid-zone .subscription__voucher .subscription-notice {
  display: none;
}

#paid-zone .subscription__voucher .order__success-title {
  color: red;
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0 40px 20px;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription__voucher .order__success-title h1 {
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription__voucher .order__success-voucher {
  margin: 0 0 30px;
}

#paid-zone .subscription__voucher .order__success-voucher .order__success-generated {
  width: 100%;
}

#paid-zone .subscription__voucher .order__success-download {
  margin: 30px 0;
}

#paid-zone .subscription__voucher .order__success-pdf {
  color: #fff;
  text-align: center;
  background: red;
  width: 260px;
  margin: 0 auto;
  padding: 11px 15px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

#paid-zone .subscription__voucher .dataimg-download-icon {
  float: left;
  width: 21px;
  height: 18px;
  margin-right: 5px;
}

#paid-zone .subscription__voucher .order__success-info {
  margin: 30px auto;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font: 15px / 18px Open Sans Bold, arial, sans-serif;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font: 12px / 18px Open Sans, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
  font-family: Open Sans, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto h2 {
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto ul {
  list-style: none;
}

#paid-zone .subscription__voucher .order__success-howto ul li {
  margin: 5px 0;
  font-size: 13px;
  line-height: 20px;
}

#paid-zone .subscription__voucher .order__success-contact {
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 12px;
  line-height: 18px;
}

#blesk-login #login-wrapper h1, #blesk-login #login-wrapper h2 {
  color: red;
}

#blesk-login #login-wrapper .login-button {
  background: red;
}

#blesk-login #terms-and-conditions-form {
  overflow: hidden;
}

#blesk-login #terms-and-conditions-form a {
  color: red;
}

#blesk-login #terms-and-conditions-form .login-button {
  float: left;
  color: #fff;
  margin-right: 10px;
}

#paid-zone #login-wrapper .form-row .required:after {
  font-size: 12px;
}

#order-subscription .table .payment input[type="radio"] {
  margin: -5px 10px 0 0;
}

#order-subscription .subscription-sidenote {
  margin: 20px 0 0;
}

#paid-zone .payment-total .discount-code {
  padding-bottom: 15px;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone .payment-total .discount-code .discount-code__label {
  margin: 0;
  display: block;
}

#paid-zone .payment-total .discount-code .discount-code__input {
  color: #000001;
  border: 1px solid #d1d4e4;
  border-radius: 3px;
  outline: none;
  width: 180px;
  margin: 10px 5px 10px 0;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: inline-block;
}

#paid-zone .payment-total .discount-code .discount-code__button {
  color: #0000ca;
  cursor: pointer;
  background: none;
  border: none;
  font-family: Open Sans Bold, arial, sans-serif;
  display: inline-block;
}

#paid-zone .payment-total .discount-code .discount-code__error {
  color: #e00a17;
}

#paid-zone .payment-total .price-note {
  font-size: 13px;
  line-height: 18px;
}

#paid-zone .payment-total .discount-code__price {
  color: #e00a17;
  padding-bottom: 10px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 18px;
  line-height: 18px;
}

.subscription__voucher .order-form .discount-code {
  display: none;
}

#order-subscription-sms #additional-info h2 {
  font-family: Open Sans Bold, arial, sans-serif;
}

#order-subscription-sms #steps li form span {
  top: 23px;
}

#order-subscription-sms #steps li .sms_input {
  width: 86px;
  padding: 0 0 0 45px;
}

.container .subscription--article.fade-out:before {
  background: linear-gradient(rgba(255, 255, 255, 0) 0% 2%, #fff 70%, #fff);
}

#blesk-premium-corporate .body .subscription-type .main .price {
  margin: 10px 0 5px;
}

#blesk-premium-corporate .body .subscription-type .main .title {
  line-height: 16px;
}

.subscription.subscription--new {
  background: #fff;
}

.subscription.subscription--new strong, .subscription.subscription--new .subscription__advantages-headline, .subscription.subscription--new .subscription__type-title, .subscription.subscription--new .subscription__advantages-headline-extra, .subscription.subscription--new .subscription__headline, .subscription.subscription--new .subscription__type-price, .subscription.subscription--new .subscription__type-order, .subscription.subscription--new .subscription__type-extra strong {
  font-family: Open Sans Bold, arial, sans-serif;
}

.subscription.subscription--new .subscription__headline {
  margin: 10px 0;
  font-size: 29px;
  line-height: 39px;
}

.subscription.subscription--new .subscription__type-subtitle {
  font-family: Open Sans, sans-serif;
}

.subscription.subscription--new .subscription__advantages-headline, .subscription.subscription--new .subscription__sub-headline, .subscription.subscription--new .subscription__advantages-headline-extra {
  text-align: left;
}

.subscription.subscription--new .subscription__type, .subscription.subscription--new .subscription__type-minimal {
  background: #f8f8f9;
}

.subscription.subscription--new .subscription__login-link {
  color: red;
}

.subscription.subscription--new .subscription__type-title, .subscription.subscription--new .subscription__type-subtitle {
  font-size: 20px;
  line-height: 28px;
}

.subscription.subscription--new .subscription__type-price {
  margin: 30px 0 15px;
  font-size: 40px;
  line-height: 19px;
}

.subscription.subscription--new .subscription__type-order {
  font-size: 15px;
  line-height: 40px;
}

.subscription.subscription--new .subscription__type-extra strong {
  font-size: 20px;
  line-height: 1.1;
}

.subscription.subscription--new .subscription__advantages-headline {
  margin: 0 0 30px !important;
}

.subscription.subscription--new .subscription__advantages-headline-extra {
  margin: 20px 0 30px !important;
}

.subscription.subscription--new .subscription__type-voucher .subscription__type-title {
  margin: 0 20px 15px 0;
}

.faq .subscription-faq__headline {
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 18px;
}

.faq .subscription-faq__answer p {
  margin: 10px 0 0 !important;
}

.faq .subscription-faq__question strong {
  font-family: Open Sans Bold, arial, sans-serif;
}

.faq a, .faq .red {
  color: red !important;
}

#login-wrapper.prozeny {
  font-family: Open Sans, arial, sans-serif;
}

#login-wrapper.prozeny h1 {
  margin-bottom: 15px;
}

#login-wrapper.prozeny h1, #login-wrapper.prozeny h2 {
  color: red;
}

#login-wrapper.prozeny p, #login-wrapper.prozeny textarea {
  font-size: 12px;
  line-height: 18px;
}

#login-wrapper.prozeny input, #login-wrapper.prozeny label, #login-wrapper.prozeny select, #login-wrapper.prozeny button, #login-wrapper.prozeny textarea {
  font-family: Open Sans, arial, sans-serif;
  font-size: 14px;
}

#login-wrapper.prozeny .login-button {
  text-transform: uppercase;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 14px;
}

#login-wrapper.prozeny .form-row input[type="file"].text {
  height: auto;
  padding: 10px 0;
  font-size: 14px;
}

#login-wrapper.prozeny .form-row input.text, #login-wrapper.prozeny .form-row label.form-input-group {
  font-size: 14px;
}

#login-wrapper.prozeny .form-row .required:after {
  color: red;
  font-size: 12px;
}

#login-wrapper.prozeny #gdpr-textarea {
  color: #616774;
  margin: 15px 0;
  padding: 15px;
}

#login-wrapper.prozeny #terms-and-conditions-form input[type="checkbox"] {
  position: relative;
  top: 3px;
}

.recent-articles {
  width: 620px;
  margin-top: 40px;
  margin-left: 10px;
}

.recent-articles .border-top {
  border-top: 2px solid red;
}

.recent-articles .articles {
  width: calc(100% + 20px);
  margin-left: -10px;
}

.recent-articles article {
  float: left;
  background-color: #fff;
  width: 140px;
  max-height: 193px;
  margin: 0 10px 5px;
  position: relative;
}

.recent-articles .more {
  text-align: center;
  margin-bottom: 10px;
}

.recent-articles .more__btn {
  text-align: center;
  color: #fff;
  background: red;
  border-radius: 2px;
  width: 214px;
  height: 30px;
  padding: 12px 26px 10px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 15px;
  line-height: 40px;
  display: inline;
  text-decoration: none !important;
}

.recent-articles .link-title {
  color: #000;
  float: left;
  margin-top: 10px;
  margin-bottom: 40px;
  font-family: Open Sans Bold, arial, sans-serif;
  line-height: 18px;
}

.recent-articles__blp .dataimg-tag-blesk-premium {
  position: absolute;
}

.recent-articles__blp .title {
  text-transform: uppercase;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 13px;
  line-height: 18px;
}

#recentarticles-premium-pane .recent-articles {
  width: 620px;
  margin-left: 0;
}

#recentarticles-premium-pane .recent-articles article {
  margin: 0 10px 5px;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.lazyload-placeholder-width-small {
  width: 120px;
  display: block;
}

.box-admin img.lazyload {
  height: auto;
}

.ecommerce img.lazyload.loaded {
  object-fit: cover;
  background-color: #e9eff4;
  width: 120px;
  height: 80px;
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot");
  src: local(Roboto Bold), local(Roboto-Bold), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#Roboto") format("svg");
}

.subcategory-menu {
  color: #1e1e1e;
  border-bottom: 1px solid #cdcdcd;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0 0 30px;
  padding: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: flex;
}

.subcategory-menu__logo-link {
  text-transform: uppercase;
  color: inherit;
  font-family: tablet_gothic_condensed, Times New Roman, serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 67px;
  text-decoration: none;
  display: flex;
}

.subcategory-menu__logo-image {
  margin-bottom: 10px;
}

.subcategory-menu__list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.subcategory-menu__item {
  padding: 15px 14px 14px;
  position: relative;
}

.subcategory-menu__item:last-child {
  margin-right: 0;
}

.subcategory-menu__item--hidden {
  display: none;
}

.subcategory-menu__item--with-submenu {
  cursor: pointer;
  padding-right: 23px;
  position: relative;
}

.subcategory-menu__item--with-submenu:hover {
  background-color: #eee;
}

.subcategory-menu__item--with-submenu:hover:after {
  transition: transform .3s linear;
  transform: translateY(-50%)scaleY(-1);
}

.subcategory-menu__item--with-submenu:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-down-dark-grey.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s linear;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%)scaleY(1);
}

.subcategory-menu__link {
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  display: block;
}

.subcategory-menu__link:hover {
  text-decoration: underline;
}

.subcategory-menu__submenu {
  z-index: 21;
  background-color: #eee;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.subcategory-menu__submenu[data-side="left"] {
  left: 0;
  right: auto;
}

.subcategory-menu .subcategory-menu__item--with-submenu:hover .subcategory-menu__submenu {
  display: block;
}

.subcategory-menu__submenu-item--hidden {
  display: none;
}

.subcategory-menu__submenu-link {
  border-bottom: 1px solid #dcdcdc;
  padding: 8px 5px 6px;
}

.subcategory-menu__submenu-item:last-child .subcategory-menu__submenu-link {
  border-bottom: none;
}

.subcategory-menu {
  margin-top: 40px;
  margin-bottom: 20px;
}

.blesk-full-nav__sidebar-specials-link {
  font-family: Open Sans Bold, arial, sans-serif;
}

.subcategory-menu__link-nejsi-v-tom-sama {
  position: absolute;
  top: 2px;
  left: -33px;
}

.clear {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  position: absolute !important;
  overflow: hidden !important;
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

.blesk-logo {
  background-color: #ed1c24;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-logo__image {
  display: block;
}

@keyframes show-navigation {
  from {
    opacity: 0;
    transform: translateY(-300px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hide-navigation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blesk-full-nav {
  z-index: 10002;
  color: #fff;
  opacity: 0;
  background-color: rgba(0, 0, 0, .95);
  width: 100%;
  height: 100%;
  padding: 80px 0 30px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@media (max-width: 1099px) {
  .blesk-full-nav {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav {
    padding-top: 20px;
  }
}

.blesk-full-nav, .blesk-full-nav *, .blesk-full-nav:before, .blesk-full-nav:after, .blesk-full-nav :before, .blesk-full-nav :after {
  box-sizing: border-box;
}

.blesk-full-nav--show {
  opacity: 1;
  animation: .2s ease-in-out show-navigation;
  display: block;
}

.blesk-full-nav--hide {
  opacity: 0;
  animation: .2s ease-in-out hide-navigation;
}

.blesk-full-nav a {
  color: inherit;
}

.blesk-full-nav a:focus {
  outline: none;
}

.blesk-full-nav__container {
  width: 960px;
  margin: 0 auto;
  padding: 0 10px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__container {
    width: 768px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__container {
    width: 100%;
    max-width: 480px;
  }
}

.blesk-full-nav__input {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  padding: 0 15px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.blesk-full-nav__input:focus {
  outline: none;
}

.blesk-full-nav__input--search {
  width: 550px;
  height: 48px;
  padding-right: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__input--search {
    width: 100%;
  }
}

.blesk-full-nav__link-with-icon {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.blesk-full-nav__link-with-icon:hover {
  text-decoration: underline;
}

.blesk-full-nav__link-with-icon:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__link-with-icon {
    font-size: 15px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__link-with-icon img {
    margin-left: 15px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon img {
    margin-right: 10px;
  }
}

.blesk-full-nav__header {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__header {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__header {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.blesk-full-nav__logo {
  width: 136px;
  height: 48px;
}

.blesk-full-nav__logo:focus {
  outline: none;
}

.blesk-full-nav__search {
  margin: auto auto auto 60px;
  position: relative;
}

@media (max-width: 1099px) {
  .blesk-full-nav__search {
    flex: 1;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__search {
    order: 1;
    min-width: 100%;
    margin: 20px 0 0;
  }
}

.blesk-full-nav__search-icon {
  opacity: .65;
  cursor: pointer;
  background: none;
  border: none;
  width: 18px;
  height: 18px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.blesk-full-nav__search-icon:focus {
  outline: none;
}

.blesk-full-nav__close-button {
  cursor: pointer;
  will-change: transform;
  will-change: opacity;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  transition: transform .2s ease-in-out;
  display: flex;
}

.blesk-full-nav__close-button:hover {
  opacity: .8;
}

.blesk-full-nav__close-button:active {
  opacity: .6;
}

.blesk-full-nav__close-button:hover {
  transform: rotate(90deg);
}

.blesk-full-nav__close-button:focus {
  outline: none;
}

.blesk-full-nav__close-button svg {
  width: 25px;
  height: 25px;
}

.blesk-full-nav__close-button path {
  fill: #fff;
}

.blesk-full-nav__main {
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__main {
    float: left;
    margin-right: 35px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__main {
    order: 1;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__main {
    width: 745px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__main {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__list-section {
    min-width: 160px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section {
    width: 100%;
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-section:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section:last-child {
    margin-bottom: 0;
  }
}

.blesk-full-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .blesk-full-nav__list {
    flex-wrap: wrap;
    display: flex;
  }
}

.blesk-full-nav__lists-group {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-full-nav__lists-group {
    min-width: 355px;
    margin-bottom: -35px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group {
    flex-direction: column;
  }
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section {
  margin-bottom: 35px;
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section:nth-child(2n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group .blesk-full-nav__list-section {
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-title {
  color: #fff;
  border-bottom: 2px solid #ed1c24;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__list-title a {
  text-decoration: none;
  color: inherit !important;
}

.blesk-full-nav__list-title a:hover {
  text-decoration: underline;
}

.blesk-full-nav__list-item {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-item {
    width: 50%;
    margin-bottom: 15px;
  }
}

.blesk-full-nav__list-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__list-link {
  text-decoration: none;
}

.blesk-full-nav__list-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-link {
    font-size: 15px;
  }
}

.blesk-full-nav__sidebar--projects {
  clear: right;
  margin-bottom: 30px;
  padding-top: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--projects {
    margin-bottom: 10px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar {
    float: right;
    width: 160px;
    padding-top: 25px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
  }
}

.blesk-full-nav__sidebar--specials {
  margin-bottom: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--specials {
    margin-bottom: 30px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-group {
    margin-bottom: 40px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group {
    display: flex;
  }
}

.blesk-full-nav__sidebar-group:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group--other {
    margin-bottom: 15px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-group--other {
    margin-right: 0;
  }
}

.blesk-full-nav__sidebar-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-list {
    flex-wrap: wrap;
    display: flex;
  }

  .blesk-full-nav__sidebar-list--projects {
    margin-right: -30px;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__sidebar-list--projects {
    margin-right: 0;
  }
}

.blesk-full-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 20px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
}

@media (min-width: 1100px) {
  .blesk-full-nav__premium-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__premium-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__stardance-tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: 108px 18px;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 18px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
  box-shadow: 0 0 2px rgba(245, 239, 220, .2), 0 0 6px rgba(252, 244, 219, .6);
}

@media (min-width: 1100px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
  justify-content: flex-start;
  text-decoration: none;
  position: relative;
}

.blesk-full-nav__ikiosek-link:hover, .blesk-full-nav__bazar-link:hover {
  text-decoration: underline;
}

@media (max-width: 1099px) {
  .blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
    width: auto;
  }
}

.blesk-full-nav__bazar-link {
  margin-top: 20px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__bazar-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-right: 10px;
  }

  .blesk-full-nav__sidebar-specials-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

.blesk-full-nav__sidebar-specials-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__sidebar-specials-link {
  background-color: #fff;
  border-radius: 2px;
  padding: 0 15px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
  display: inline-block;
  color: #1e1e1e !important;
}

.blesk-full-nav__sidebar-specials-link:hover {
  text-decoration: underline;
}

.blesk-full-nav__sidebar-projects-item {
  margin-bottom: 20px;
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-projects-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-projects-item {
    align-items: center;
    width: 25%;
    padding-right: 20px;
    display: flex;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-projects-item {
    width: calc(50% - 10px);
    padding-right: 0;
  }

  .blesk-full-nav__sidebar-projects-item:nth-child(odd) {
    margin-right: 20px;
  }
}

.blesk-full-nav__footer {
  border-top: 1px solid rgba(255, 255, 255, .5);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__footer {
    margin-top: 30px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__footer {
    flex-direction: column;
    order: 1;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__footer {
    flex-flow: column wrap;
    align-items: center;
  }
}

.blesk-full-nav__socials {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__socials {
    order: 2;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__socials-list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-full-nav__socials-item {
  margin-right: 15px;
}

.blesk-full-nav__socials-item:last-child {
  margin-right: 0;
}

.blesk-full-nav__socials-link {
  will-change: opacity;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-full-nav__socials-link:hover {
  opacity: .8;
}

.blesk-full-nav__socials-link:active {
  opacity: .6;
}

.blesk-full-nav__socials-link:focus {
  outline: none;
}

.blesk-full-nav__contacts {
  align-items: center;
  font-size: 15px;
  line-height: 17px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__contacts {
    order: 2;
  }
}

.blesk-full-nav__contacts-phone {
  border-left: 1px solid #fff;
  margin-left: 10px;
  padding-left: 10px;
}

.blesk-full-nav__applications {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications {
    flex-direction: column;
    order: 1;
    align-items: center;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__applications-titles {
  flex-direction: column;
  margin-right: 12px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-titles {
    margin-right: 0;
  }
}

.blesk-full-nav__applications-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__applications-title > a {
  text-decoration: none;
}

.blesk-full-nav__applications-title > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-subtitle {
    text-align: center;
  }
}

.blesk-full-nav__applications-subtitle > a {
  text-decoration: none;
}

.blesk-full-nav__applications-subtitle > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-stores {
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-stores {
    margin-top: 32px;
  }
}

.blesk-full-nav__applications-google {
  margin-right: 8px;
  line-height: 0;
}

.blesk-full-nav__applications-apple {
  line-height: 0;
}

.blesk-header {
  z-index: 10001;
  color: #fff;
  width: 100%;
  min-width: 1080px;
  height: 126px;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.blesk-header, .blesk-header *, .blesk-header:before, .blesk-header:after, .blesk-header :before, .blesk-header :after {
  box-sizing: border-box;
}

.blesk-header--responsive {
  min-width: 0;
}

@media (min-width: 1100px) {
  .blesk-header--responsive {
    min-width: 1080px;
    height: 125px;
    padding-top: 0 !important;
  }
}

@media (max-width: 1099px) {
  .blesk-header--responsive {
    height: auto;
  }
}

.blesk-header img {
  display: block;
}

.blesk-header a:hover {
  color: inherit;
}

.blesk-header a:focus {
  outline: none;
}

.blesk-header__link {
  color: #fff;
  text-decoration: none;
}

.blesk-header__link:not([href]):hover {
  text-decoration: none;
}

.blesk-header__top {
  background-color: #ed1c24;
}

@media (max-width: 767px) {
  .blesk-header__top {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.blesk-header__bottom {
  background-color: #ed1c24;
}

@media (min-width: 768px) {
  .blesk-header__bottom {
    background-color: #fff;
    height: 44px;
  }
}

.blesk-header__container {
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .blesk-header--full-width .blesk-header__container {
    width: auto;
    min-width: 1110px;
    max-width: 1620px;
    padding: 0 30px;
  }

  .blesk-header__container {
    width: 1080px;
  }
}

.blesk-header__magazines {
  height: 0;
}

@media (min-width: 1100px) {
  .blesk-header__magazines {
    height: 34px;
  }
}

@media (max-width: 1099px) {
  .blesk-header__magazines {
    display: none;
  }

  .blesk-header--breaking-visible .blesk-header__magazines {
    display: block;
  }
}

.blesk-header__magazines--hide {
  display: none !important;
}

.blesk-header__inner {
  align-items: center;
  padding: 4px 0 0;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-header__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header__inner {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    justify-content: space-between;
    padding: 8px 0;
  }
}

.blesk-header__logo {
  margin: 4px 30px 10px 0;
}

@media (max-width: 767px) {
  .blesk-header__logo {
    display: none;
  }
}

.blesk-header__day-info {
  margin-right: auto;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-header__day-info {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .blesk-header__day-info {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .blesk-header__day-info {
    font-size: 11px;
  }
}

.blesk-header__day-info a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .blesk-header__date {
    padding-right: 10px;
  }

  .blesk-header__name-day {
    border-left: 1px solid #fff;
    padding-left: 10px;
  }
}

.blesk-header__weather {
  color: #fff;
  will-change: opacity;
  align-self: stretch;
  align-items: center;
  padding: 0 10px 0 30px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__weather:hover {
  opacity: .8;
}

.blesk-header__weather:active {
  opacity: .6;
}

.blesk-header__weather:hover {
  text-decoration: none;
}

.blesk-header__weather:after {
  content: "";
  z-index: 1;
  border-top: 2px solid #fff;
  border-bottom: none;
  border-left: none;
  border-right: 2px solid #fff;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%)rotate(45deg);
}

@media (min-width: 768px) {
  .blesk-header__weather {
    margin-right: 30px;
  }
}

.blesk-header__weather-icon {
  height: 26px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.blesk-header__daily-feed {
  will-change: opacity;
}

.blesk-header__daily-feed:hover {
  opacity: .8;
}

.blesk-header__daily-feed:active {
  opacity: .6;
}

@media (min-width: 768px) {
  .blesk-header__daily-feed {
    display: none;
  }
}

.blesk-header-nav .blesk-header__daily-feed {
  width: 22px;
  height: 22px;
  margin-inline-start: auto;
  margin-inline-end: 16px;
  position: relative;
}

.blesk-header-nav .blesk-header__daily-feed svg {
  width: 100%;
  height: 100%;
}

.blesk-header__login {
  color: #fff;
  will-change: opacity;
  flex-direction: column;
  align-items: center;
  max-height: 18px;
  font-size: 9px;
  line-height: 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__login:hover {
  opacity: .8;
}

.blesk-header__login:active {
  opacity: .6;
}

.blesk-header__login--desktop {
  margin-top: -2px;
}

.blesk-header__login:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-header__login {
    display: none;
  }
}

.blesk-header-nav .blesk-header__login {
  color: #fff;
  flex-direction: row;
  max-height: none;
  font-size: 11px;
  font-weight: 400;
  display: flex;
}

.blesk-header-nav .blesk-header__login:hover {
  color: #fff;
}

@media (min-width: 768px) {
  .blesk-header-nav .blesk-header__login {
    display: none;
  }
}

.blesk-header__login:has(.blesk-header__login-text--initials) {
  text-align: center;
  color: red;
  background: #fff;
  border-radius: 51%;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-weight: 700;
  line-height: 18px;
}

.blesk-header__login:has(.blesk-header__login-text--initials):hover {
  color: red;
  text-decoration: none;
}

.blesk-header__login-image {
  border-radius: 51%;
  margin-bottom: 2px;
}

@media (max-width: 767px) {
  .blesk-header__login-image {
    width: 22px;
    height: 22px;
  }

  .blesk-header-nav .blesk-header__login-image {
    order: 1;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 0;
  }
}

.blesk-header__search-icon {
  cursor: pointer;
  will-change: opacity;
  background: none;
  border: none;
  min-width: 18px;
  margin-left: 15px;
  padding: 0;
  transition: opacity .2s linear;
}

.blesk-header__search-icon:hover {
  opacity: .8;
}

.blesk-header__search-icon:active {
  opacity: .6;
}

.blesk-header__search-icon:focus {
  outline: none;
}

@media (max-width: 767px) {
  .blesk-header__search-icon {
    display: none;
  }

  .body--article .blesk-header__top .cnc-menu nav, .body--article .blesk-header__inner, .body--article .blesk-header-nav__menu-list, .body--article .blesk-header__search-icon, .body--article .blesk-header-nav__ikiosek-link, .body--article .blesk-header-nav__premium-link {
    display: none !important;
  }
}

.blesk-header-magazines .cnc-menu {
  height: 34px;
  margin-bottom: 0;
  line-height: 34px;
  box-shadow: none !important;
}

.blesk-header-magazines .cnc-menu:after {
  content: none !important;
}

@media (min-width: 1100px) {
  .blesk-header-magazines .cnc-menu {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }
}

.blesk-header-magazines .cnc-menu nav {
  z-index: auto;
  background: none;
  width: auto;
  min-height: auto;
  top: auto;
  left: auto;
}

.blesk-header-magazines .cnc-menu li ul {
  top: 34px;
}

.blesk-header-magazines .cnc-menu li ul li a {
  padding: 0;
}

.blesk-header-magazines .cnc-menu__list {
  align-items: center;
  display: flex;
}

.blesk-header-magazines .cnc-menu__list:after {
  content: none;
}

.blesk-header-magazines .cnc-menu__list a {
  padding: 0 20px;
  color: rgba(255, 255, 255, .6) !important;
  cursor: pointer !important;
  border: none !important;
  border-right: 1px solid rgba(0, 0, 0, .2) !important;
  margin: 0 !important;
}

.blesk-header-magazines .cnc-menu__list a:hover {
  color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:hover:before, .blesk-header-magazines a[href*="javascript"]:hover:after {
  background-color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:before, .blesk-header-magazines a[href*="javascript"]:after {
  pointer-events: none !important;
  background-color: rgba(255, 255, 255, .6) !important;
}

.blesk-header-magazines li:last-child a {
  border-right: none !important;
}

.blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
  height: 34px !important;
}

@media (max-width: 959px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    height: 75px !important;
  }
}

@media (max-width: 321px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    max-height: 94px;
  }
}

.blesk-header-nav {
  color: #1e1e1e;
  will-change: transform;
  background-color: #fff;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav {
    background-color: #ed1c24;
    justify-content: space-between;
  }
}

.blesk-header-nav a {
  color: inherit;
  font-family: inherit;
}

.blesk-header-nav a:focus {
  outline: none;
}

@media (min-width: 768px) {
  .blesk-header-nav__logo {
    min-width: 182px;
    height: 74px;
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__logo {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.blesk-sticky-header .blesk-header-nav__logo {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-right: 30px;
  display: flex;
}

.blesk-header-nav__hamburger-button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  padding: 0 0 0 17px;
  position: relative;
}

.blesk-header-nav__hamburger-button:focus {
  outline: none;
}

.blesk-header-nav__hamburger-button:before {
  content: "";
  color: #1e1e1e;
  background-color: currentColor;
  width: 13px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  box-shadow: 0 4px, 0 -4px;
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button:before {
    color: #fff;
    width: 20px;
    height: 3px;
    box-shadow: 0 6px, 0 -6px;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__hamburger-button {
    margin-right: 25px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button {
    order: -1;
    width: 14px;
    font-size: 0;
  }
}

.blesk-header-nav__menu-list {
  align-items: center;
  margin: 0 auto 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-sticky-header .blesk-header-nav__menu-list {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .blesk-header-nav__menu-list {
    display: none;
  }
}

.blesk-header-nav__menu-item {
  margin-right: 23px;
}

.blesk-sticky-header .blesk-header-nav__menu-item {
  white-space: nowrap;
  order: 0;
}

.blesk-sticky-header .blesk-header-nav__menu-item--show {
  visibility: visible;
}

.blesk-sticky-header .blesk-header-nav__menu-item--hidden {
  visibility: hidden;
  order: 1;
  display: block;
}

.blesk-header-nav__menu-item:last-child {
  margin-right: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav__menu-item:nth-child(n+8) {
    display: none;
  }
}

.blesk-header-nav__menu-link {
  text-decoration: none;
}

.blesk-header-nav__menu-link:hover {
  text-decoration: underline;
}

.blesk-header-nav__menu-link.menu-link_soutez {
  color: #000;
  text-transform: uppercase;
  background-color: #ffcf00;
  padding: 5px;
}

.blesk-header-nav__menu-link.menu-link_soutez:hover {
  color: #000;
}

.blesk-header-nav__search-icon {
  display: none;
}

@media (min-width: 768px) {
  .blesk-sticky-header .blesk-header-nav__search-icon {
    display: block;
  }
}

.blesk-header-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  width: 83px;
  min-width: 83px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

.blesk-sticky-header .blesk-header-nav__premium-link {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .blesk-header-nav__premium-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__premium-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: contain;
  width: 108px;
  min-width: 108px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

@media (max-width: 767px) {
  .blesk-header-nav__tipovacka-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__tipovacka-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__secondary-logo {
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-logo {
  color: inherit;
  flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;
  padding-top: 5px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 26px;
  line-height: 29px;
  display: block;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-logo {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-logo img {
  margin: -5px 0 0;
  display: block;
}

.blesk-header-nav__secondary-menu-list {
  margin-right: auto;
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list {
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-menu-list {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu {
  overflow-x: unset;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item.blesk-header-nav__menu-item--hidden, .blesk-sticky-header .subcategory-menu__list ~ .blesk-header-nav__menu-list {
  display: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item {
  color: #000;
  margin-right: 25px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:last-child {
  margin-right: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--hidden {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--with-submenu:hover {
  background: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:not(.subcategory-menu__item--with-submenu) {
  padding-right: 0;
}

.blesk-sticky-header .subcategory-menu__item {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__link {
  font-size: inherit;
  line-height: inherit;
  border: none;
  padding: 0;
}

.blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item--with-submenu:hover .subcategory-menu__submenu {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.blesk-sticky-header {
  z-index: 9999;
  align-items: center;
  min-height: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@media (min-width: 768px) {
  .blesk-sticky-header {
    padding-left: 0;
  }
}

.blesk-sticky-header, .blesk-sticky-header *, .blesk-sticky-header:before, .blesk-sticky-header:after, .blesk-sticky-header :before, .blesk-sticky-header :after {
  box-sizing: border-box;
}

.blesk-sticky-header .blesk-header-nav__secondary-logo img {
  width: 158px;
  height: 34px;
}

.blesk-sticky-header .subcategory-menu__link-nejsi-v-tom-sama {
  display: none;
}

.banner-print * {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

.banner-print > a {
  float: left;
  background-image: linear-gradient(to left, #f5f6f6, #e4ebeb);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 104px;
  margin: 25px 0 20px;
  display: flex;
}

.banner-print > a:hover {
  text-decoration: none;
}

.banner-print img {
  margin-left: 20px;
  display: block;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.banner-print span:nth-child(2) {
  text-align: center;
  color: #16212d;
  text-transform: uppercase;
  align-items: center;
  width: 206px;
  height: 77px;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 32px;
  line-height: 1.06;
  display: flex;
  position: relative;
}

.banner-print span:nth-child(3) {
  text-shadow: 0 1px 6px #f4f5f5;
  text-align: center;
  color: #16212d;
  align-items: center;
  width: 235px;
  height: 28px;
  font-family: Open Sans Semibold, arial, sans-serif;
  font-size: 20px;
  font-style: italic;
  display: flex;
  position: relative;
}

.banner-print span:nth-child(3):before {
  content: " ";
  background: url("/images/blesk-prozeny/plus.svg") no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: -41px;
  transform: translateY(-50%);
}

.banner-print span:last-child {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background-color: red;
  width: 180px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 10px 24px 11px;
  font-family: Open Sans Bold, sans-serif;
  font-size: 13px;
}

.internal-promo.variation {
  border-top: none;
  margin: 0;
}

.internal-promo.variation h2 {
  color: red;
  border-bottom: 1px solid red;
  padding-bottom: 5px;
  font-family: Open Sans Light, sans-serif;
  font-size: 25px;
  line-height: 1.2em;
}

.internal-promo.variation h2 a {
  color: red;
}

.internal-promo.variation .internal-promo-item__image {
  background-color: #e9eff4;
}

.internal-promo.variation .internal-promo-item__title {
  color: #16212d;
}

.internal-promo.variation .internal-promo-item__more {
  color: red;
  border-bottom-color: red;
}

.internal-promo.variation .internal-promo-item__more:before, .internal-promo.variation .internal-promo-item__more:after {
  background-color: red;
}

.internal-promo.variation .internal-promo-item__more:hover {
  color: #707b87;
}

.internal-promo.variation .internal-promo-item__more:hover:before, .internal-promo.variation .internal-promo-item__more:hover:after {
  background-color: #707b87;
}

.widget-product-banner {
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-banner .product-image {
  width: 30%;
  margin-right: 3%;
}

.widget-product-banner .product-image .product-image-thumbnail {
  border: 1px solid #f5f5f5;
  width: 100%;
}

.widget-product-banner .product-description {
  flex-direction: column;
  align-items: flex-start;
  width: 67%;
  display: flex;
}

.widget-product-banner .product-description .product-name .product-link {
  color: #d00000;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: underline;
}

.widget-product-banner .product-description .product-availability {
  font-size: 1.3em;
}

.widget-product-banner .product-description .product-availability .product-price-range {
  white-space: nowrap;
  font-weight: 700;
}

.widget-product-banner .product-description .product-compare-link {
  color: #fff;
  text-transform: uppercase;
  background: #d00000;
  border-radius: 5px;
  margin-top: 7px;
  padding: 2px 10px;
  font-size: 1.2em;
  font-weight: 700;
  display: inline-block;
  text-decoration: none !important;
}

@media screen and (min-width: 768px) {
  .widget-product-banner .product-description .product-name .product-link {
    font-size: 16px;
    line-height: 25px;
  }

  .widget-product-banner .product-description .product-availability {
    font-size: 13px;
    line-height: 25px;
  }

  .widget-product-banner .product-description .product-compare-link {
    font-size: 12px;
    line-height: 25px;
  }
}

.widget-product-banner-with-shops {
  border-top: 2px solid #d00000;
  border-bottom: 2px solid #d00000;
  flex-direction: column;
  margin: 20px 0;
  padding: 2em 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-banner-with-shops .product-name {
  margin: 0 0 2em;
}

.widget-product-banner-with-shops .product-name .product-link {
  color: #d00000;
  font-size: 2em;
  font-weight: 700;
  line-height: 2.4em;
  text-decoration: underline;
}

.widget-product-banner-with-shops .product-image {
  border: 1px solid #f5f5f5;
}

.widget-product-banner-with-shops .product-image img {
  max-width: 100%;
}

.widget-product-banner-with-shops .product-shops .product-shop {
  border-top: 1px solid #dcdcdc;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: .7em 0;
  padding: .7em 0;
  display: flex;
}

.widget-product-banner-with-shops .product-shops .product-shop:first-of-type {
  border-top: 0;
}

.widget-product-banner-with-shops .product-shops .product-shop .shop-logo {
  width: 80px;
}

.widget-product-banner-with-shops .product-shops .product-shop .shop-price-button {
  color: #fff;
  text-transform: none;
  background: #d00000;
  border-radius: 5px;
  padding: .2em 2.3em;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 2em;
  display: inline-block;
  text-decoration: none !important;
}

.widget-product-banner-with-shops .product-shops .product-show-more {
  text-align: center;
  color: #d00000;
  font-size: 1.7em;
  font-weight: 700;
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .widget-product-banner-with-shops {
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding: 25px 0;
  }

  .widget-product-banner-with-shops .product-name {
    margin: 0 0 20px;
  }

  .widget-product-banner-with-shops .product-name .product-link {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex {
    flex-direction: row;
    display: flex;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-image {
    order: 2;
    width: 46%;
    margin-left: 2%;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-image img {
    border: 1px solid #dcdcdc;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops {
    width: 52%;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop {
    border-top: 1px solid #dcdcdc;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 0;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop:first-of-type {
    border-top: 0;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop .shop-logo img {
    width: 100%;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop .shop-price-button {
    text-align: center;
    min-width: 130px;
    padding: 2px 10px;
    font-size: 12px;
    line-height: 22px;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-show-more {
    font-size: 16px;
  }
}

.widget-product-price-range {
  font-size: 1em;
  line-height: inherit;
  display: inline-block;
  color: #d00000 !important;
}

.widget-product-price-range-button {
  color: #d00000;
  flex-justify-content: flex-start;
  flex-align-items: center;
  flex-flow: wrap;
  width: 100%;
  margin: 20px 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-price-range-button .product-price {
  color: #000;
  font-size: 2em;
  font-weight: 700;
}

.widget-product-price-range-button .product-price-range {
  margin: 0 .5em;
  font-size: 2em;
  text-decoration: underline;
  color: #d00000 !important;
}

.widget-product-price-range-button .product-compare-link {
  color: #fff;
  text-transform: none;
  background: #d00000;
  border-radius: 5px;
  margin: .5em 0 0;
  padding: .2em 2.3em;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 2em;
  display: inline-block;
  text-decoration: none !important;
}

@media screen and (min-width: 768px) {
  .widget-product-price-range-button .product-price, .widget-product-price-range-button .product-price-range {
    font-size: 16px;
  }

  .widget-product-price-range-button .product-compare-link {
    text-transform: uppercase;
    padding: 2px 10px;
    font-size: 12px;
    line-height: 22px;
  }
}

.widget-product-most-popular {
  border-top: 2px solid #d00000;
  border-bottom: 2px solid #d00000;
  flex-direction: column;
  margin: 20px 0;
  padding: 2em 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-most-popular .most-popular-title {
  color: #d00000;
  margin: 0 0 1em;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.2em;
}

.widget-product-most-popular .product {
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 2em;
  display: flex;
}

.widget-product-most-popular .product .product-image {
  border: 1px solid #f5f5f5;
  width: 30%;
  margin-right: 3%;
}

.widget-product-most-popular .product .product-description {
  flex-direction: column;
  align-items: flex-start;
  width: 67%;
  display: flex;
}

.widget-product-most-popular .product .product-description .product-name {
  color: #d00000;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: underline;
}

.widget-product-most-popular .product .product-description .product-availability {
  font-size: 1.3em;
}

.widget-product-most-popular .product .product-description .product-availability .product-price-range {
  white-space: nowrap;
  font-weight: 700;
}

.widget-product-most-popular .product .product-description .product-compare-link {
  color: #fff;
  text-transform: uppercase;
  background: #d00000;
  border-radius: 5px;
  margin-top: 7px;
  padding: 2px 10px;
  font-size: 1.2em;
  font-weight: 700;
  display: inline-block;
  text-decoration: none !important;
}

.widget-product-most-popular .product-show-more {
  text-align: center;
  color: #d00000;
  font-size: 1.7em;
  font-weight: 500;
  text-decoration: underline;
  display: block;
}

.widget-product-most-popular-mini {
  flex-direction: column;
  margin: 20px 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-most-popular-mini .most-popular-title {
  margin: 0 0 1em;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.2em;
}

.widget-product-most-popular-mini ul {
  margin: 0;
  padding-left: 30px;
  list-style: outside;
}

.widget-product-most-popular-mini .product {
  margin-bottom: .5em;
  font-size: 1.6em;
  line-height: 1.4em;
}

.widget-product-most-popular-mini .product:before {
  display: none;
}

.widget-product-most-popular-mini .product .product-name {
  color: #d00000;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.3em;
  text-decoration: underline;
}

.widget-product-most-popular-mini .product .product-price-range {
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .widget-product-most-popular {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .widget-product-most-popular .most-popular-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;
  }

  .widget-product-most-popular .product .product-description .product-name {
    font-size: 16px;
    line-height: 25px;
  }

  .widget-product-most-popular .product .product-description .product-availability {
    font-size: 13px;
    line-height: 25px;
  }

  .widget-product-most-popular .product .product-description .product-compare-link {
    font-size: 12px;
    line-height: 25px;
  }

  .widget-product-most-popular .product-show-more, .widget-product-most-popular-mini .most-popular-title, .widget-product-most-popular-mini .product .product-name, .widget-product-most-popular-mini .product .product-price-range {
    font-size: 16px;
    line-height: 25px;
  }
}

